import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { i18n } from 'i18n';
import * as authTypes from 'model/authTypes';
import UserDetailsForm from './forms/UserDetailsForm';

const UserInfoWrapper = props => {
    const { formikRef } = props;

    const { enqueueSnackbar } = useSnackbar();

    const initialValues = {
        active: true,
        username: '',
        authType: authTypes.NONE,
        newPassword: '',
        oldPassword: '',
        dn: '',
        name: '',
        firstSurname: '',
        secondSurname: '',
        phoneNumber: '',
        mobilePhoneNumber: '',
        email: '',
        image: '',
        imageType: '',
        externalCode: '',
        allowedLogin: false,
    };

    const validationSchema = Yup.object({
        active: Yup.boolean().required(i18n.requiredField),
        username: Yup.string().required(i18n.requiredField),
        authType: Yup.string().oneOf(Object.keys(authTypes)).required(i18n.requiredField),
        password: Yup.string(),
        dn: Yup.string(),
        name: Yup.string().required(i18n.requiredField),
        firstSurname: Yup.string(),
        secondSurname: Yup.string(),
        phoneNumber: Yup.string(),
        mobilePhoneNumber: Yup.string(),
        email: Yup.string(),
        image: Yup.string(),
        imageType: Yup.string(),
        externalCode: Yup.string().nullable(),
        allowedLogin: Yup.boolean().required(i18n.requiredField),
    });

    return (
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(false);
                let userInfo;

                if (!props.newUser) {
                    userInfo = { ...props.selectedUser };
                }
                userInfo = {
                    ...userInfo,
                    ...values,
                };
                const shouldNewPasswordExist =
                    (props.newUser && userInfo.authType !== authTypes.LDAP && userInfo.authType !== authTypes.NONE) ||
                    (userInfo.authenticationMethods
                        ? !userInfo.authenticationMethods.some(am => am.type === authTypes.LOCAL) && userInfo.authType === authTypes.LOCAL
                        : false);

                const ldapError = userInfo.authType === authTypes.LDAP && userInfo.dn === '';

                if ((shouldNewPasswordExist && userInfo.newPassword === '') || ldapError)
                    enqueueSnackbar(i18n.passwordRequired, { variant: 'error' });
                else {
                    props.handleSaveUser(userInfo, props.newUser);
                    resetForm();
                }
            }}
        >
            {componentProps => <UserDetailsForm {...componentProps} {...props} />}
        </Formik>
    );
};

export default UserInfoWrapper;
