import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { i18n } from 'i18n';
import RoleDetailsForm from './form/RolesDetailsForm';

const RolesDetails = props => {
    const { handleSaveRole, formikRef } = props;

    const initialValues = {
        roleName: '',
        description: '',
        permissions: [],
        active: true,
    };

    const validationSchema = Yup.object({
        roleName: Yup.string().required(i18n.requiredField),
    });

    return (
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(false);
                handleSaveRole(values);
                resetForm();
            }}
        >
            {componentProps => <RoleDetailsForm {...componentProps} {...props} />}
        </Formik>
    );
};

export default RolesDetails;
