import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Checkbox } from '@material-ui/core';
import { useAssignment } from '../AssignmentWrapper';
import { SkeletonList } from 'components/generic/loader/List';

export default function RolesListAllGroups(props) {
    const { roles, assignedRoles, setChecked, groups } = props;

    const { isLoadingRoles } = useAssignment();

    if (isLoadingRoles) return <SkeletonList numRows={8} rowHeight={40} />;

    return (
        <List className="w-100 h-100 p-0" style={{ overflowY: 'auto' }}>
            {roles.map(role => (
                <ListItem button dense key={role.roleId} onClick={event => setChecked(role, event.target.checked)}>
                    <ListItemIcon>
                        <Checkbox edge="start" checked={isRolSetInAllGroups(assignedRoles, groups, role)} tabIndex={-1} disableRipple />
                    </ListItemIcon>
                    <ListItemText primary={role.roleName} />
                </ListItem>
            ))}
        </List>
    );
}

function isRolSetInAllGroups(userRoles, groups, actualRol) {
    let isRolSetInAllGroups = true;
    const filteredRoles = userRoles.filter(element => element.roleId === actualRol.roleId);
    if (groups.length === 0) {
        isRolSetInAllGroups = false;
    } else {
        groups.forEach(group => {
            const rolFound = filteredRoles.find(role => role.usersGroupId === group.usersGroupId);
            if (!!!rolFound) {
                isRolSetInAllGroups = false;
            }
        });
    }
    return isRolSetInAllGroups;
}
