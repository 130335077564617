import React, { useState } from 'react';
import { Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, useTheme } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import UsersAssignableToGroup from '../others/UsersAssignableToGroup';
import { Searcher } from 'components/generic/search/Searcher';
import { filterUsers, formatCompleteUserName } from 'utils/user-utils';
import { i18n } from 'i18n';

const GroupsDetailsList = props => {
    const { users, values, setFieldValue } = props;

    const [openModal, setOpenModal] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedUsers, setSelectedUsers] = useState([]);

    const theme = useTheme();

    const unassignUser = () => {
        const selectedIds = selectedUsers.map(us => us.userId);
        setFieldValue(
            'users',
            (values?.users ?? []).filter(usr => !selectedIds.includes(usr?.userId))
        );
        setSelectedUsers([]);
    };

    const assignUser = () => {
        setOpenModal(true);
    };
    const onCloseModal = newToAssign => {
        setOpenModal(false);
        setFieldValue('users', [...(values?.users ?? []), ...newToAssign]);
    };

    const groupUsers = React.useMemo(() => {
        const tempUsers = (values.users ?? []).map(u => ({ ...u, ...(users ?? []).find(us => us.userId === u.userId) }));

        return tempUsers
            .sort((a, b) => formatCompleteUserName(a, true).localeCompare(formatCompleteUserName(b, true)))
            .filter(u => u.active);
    }, [users, values.users]);

    const assignedUsers = React.useMemo(() => filterUsers(groupUsers, searchText), [groupUsers, searchText]);

    return (
        <Box height={1} width={1} display="flex" flexDirection="column" wrap="nowrap">
            <Box width={1} display="flex">
                <Typography variant="h5" style={{ flexGrow: 1 }}>
                    {i18n.assignedUsers}
                </Typography>
                <Box>
                    <>
                        {selectedUsers.length > 0 ? (
                            <Tooltip title={i18n.unassignUser}>
                                <Button style={{ border: '1px solid', marginRight: theme.spacing(1) }} onClick={() => unassignUser()}>
                                    <PersonAddDisabledIcon style={{ marginRight: theme.spacing(1) }} /> {i18n.unassignUser}
                                </Button>
                            </Tooltip>
                        ) : null}
                        <Tooltip title={i18n.asignUser}>
                            <Button style={{ border: '1px solid' }} onClick={() => assignUser()}>
                                <PersonAddIcon style={{ marginRight: theme.spacing(1) }} /> {i18n.asignUser}
                            </Button>
                        </Tooltip>
                    </>
                </Box>
            </Box>

            <Box paddingTop={2} width={1} flexGrow={1} display="flex" flexDirection="column" wrap="nowrap" style={{ overflowY: 'auto' }}>
                <Searcher value={searchText} onChange={setSearchText} />
                <Box width={1} flexGrow={1} style={{ overflowY: 'auto' }}>
                    <UsersAssignableToGroup groupUsers={assignedUsers} checked={selectedUsers} setChecked={setSelectedUsers} />
                </Box>
                {openModal && (
                    <AssignableUsersModal
                        open={openModal}
                        setOpen={setOpenModal}
                        groupUsers={groupUsers}
                        users={users}
                        onAccept={newUsersToAssign => onCloseModal(newUsersToAssign)}
                        onCancel={() => setOpenModal(false)}
                    />
                )}
            </Box>
        </Box>
    );
};

function AssignableUsersModal(props) {
    const { open, setOpen, groupUsers, users, onCancel, onAccept } = props;

    const theme = useTheme();

    //TODO: add pagination (Local or server side)

    const [searchTextDialog, setSearchTextDialog] = useState('');
    const [newUsersToAssign, setNewUsersToAssign] = useState([]);

    const { assignableUsers, filteredUsers } = React.useMemo(() => {
        const userIds = (groupUsers ?? []).map(u => u?.userId);

        const assignableUsers = users.filter(usr => !userIds.includes(usr.userId) && usr.active);

        const filteredUsers = filterUsers(assignableUsers, searchTextDialog);

        return { assignableUsers, filteredUsers };
    }, [groupUsers, searchTextDialog, users]);

    return (
        <Dialog
            open={open}
            onClose={setOpen}
            className="h-100 d-flex flex-column"
            fullWidth={true}
            maxWidth={'sm'}
            scroll={'paper'}
            disableBackdropClick
        >
            <DialogTitle>{i18n.assignUsers}</DialogTitle>
            <DialogContent className=" w-100 d-flex flex-column overflow-hidden" style={{ height: '60vh' }}>
                {assignableUsers && assignableUsers.length > 0 ? (
                    <>
                        <div className="row no-gutters">
                            <Searcher value={searchTextDialog} onChange={txt => setSearchTextDialog(txt)} />
                        </div>

                        <div className="row no-gutters w-100 overflow-auto" style={{ height: '85%' }}>
                            <UsersAssignableToGroup
                                groupUsers={filteredUsers}
                                checked={newUsersToAssign}
                                setChecked={setNewUsersToAssign}
                            />
                        </div>
                    </>
                ) : (
                    <span>{i18n.noUsersOutGroup}</span>
                )}
            </DialogContent>
            <DialogActions>
                <Button style={{ border: '1px solid', marginRight: theme.spacing(1) }} onClick={() => onCancel()}>
                    {i18n.cancel}
                </Button>
                <Button style={{ border: '1px solid', marginRight: theme.spacing(1) }} onClick={() => onAccept(newUsersToAssign)}>
                    {i18n.accept}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default GroupsDetailsList;
