import React from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Typography, Avatar, makeStyles, Grid } from '@material-ui/core';
import { CheckboxWithLabel } from 'formik-material-ui';
import { i18n } from 'i18n';
import { imageToB64 } from 'utils/imageUtils';
import SncFileUpload from 'components/generic/file-upload/snc-file-upload.component';

const UserInfoForm = props => {
    const classes = useStyles();

    if (!props.values.active && props.values.allowedLogin) {
        props.setFieldValue('allowedLogin', false);
    }

    return (
        <div className="row no-gutters p-1 w-100 d-flex flex-column">
            <div className="w-100 d-flex flex-column">
                <div className="row no-gutters mb-3">
                    <Typography variant="h4" gutterBottom>
                        {i18n.personalData}
                    </Typography>
                </div>
            </div>
            <div className="row no-gutters flex-grow-1">
                <div className="row no-gutters w-100 align-items-center align-self-center">
                    <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-2 h-100">
                        <div className="w-100 h-100 d-flex flex-column">
                            {props.values.image ? (
                                <div className="row no-gutters mb-3">
                                    <div className="h-100 col d-flex"></div>
                                    <div className="col-auto">
                                        <Avatar
                                            className="img-responsive fit-image"
                                            src={`data:${props.values.imageType};base64,${props.values.image}`}
                                            style={{ height: 120, width: 120 }}
                                        />
                                    </div>
                                    <div className="col" />
                                </div>
                            ) : null}
                            <SncFileUpload
                                imagePreviewMaxHeight={100}
                                className={`w-100 h-100 ${classes.upload}`}
                                acceptedFileTypes={['image/*']}
                                maxFileSize={'1MB'}
                                allowMultiple={false}
                                instantUpload
                                server={{
                                    process: (fieldName, file, metadata, load) => {
                                        imageToB64(file).then(b64 => {
                                            props.setFieldValue('image', b64.substr(b64.indexOf(',') + 1));
                                            props.setFieldValue('imageType', b64.substring(b64.indexOf(':') + 1, b64.indexOf(';')));
                                            load(b64);
                                        });
                                    },
                                    load: (source, load) => {
                                        fetch(source)
                                            .then(res => {
                                                return res.blob();
                                            })
                                            .then(blob => {
                                                return load(blob);
                                            });
                                    },
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-sm-8 col-md-8 col-xl-8">
                        <div className="row no-gutters">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-1">
                                <Field
                                    name="name"
                                    required
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label={i18n.name}
                                    component={TextField}
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-1">
                                <Field
                                    name="firstSurname"
                                    required
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label={i18n.firstSurname}
                                    component={TextField}
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-1">
                                <Field
                                    name="secondSurname"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label={i18n.secondSurname}
                                    component={TextField}
                                />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-1">
                                <Field
                                    name="phoneNumber"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label={i18n.telephone}
                                    component={TextField}
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-1">
                                <Field
                                    name="mobilePhoneNumber"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label={i18n.mobile}
                                    component={TextField}
                                />
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-1">
                                <Field name="email" type="text" variant="outlined" fullWidth label={i18n.email} component={TextField} />
                            </div>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 col-sm-12 col-md-12 col-xl-4 p-1">
                                <Field
                                    name="externalCode"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label={i18n.externalCode}
                                    component={TextField}
                                />
                            </div>
                            <Grid item className={classes.label}>
                                <Field
                                    name="active"
                                    component={CheckboxWithLabel}
                                    Label={{ label: i18n.active }}
                                    type="checkbox"
                                    color="primary"
                                />
                            </Grid>

                            <Grid item className={classes.label}>
                                <Field
                                    name="allowedLogin"
                                    component={CheckboxWithLabel}
                                    Label={{ label: i18n.allowedLogin }}
                                    type="checkbox"
                                    color="primary"
                                    disabled={props.values.active ? false : true}
                                />
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles({
    label: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControlLabel-root': {
            margin: 0,
        },
    },
    upload: {
        '& .filepond--credits': {
            display: 'none',
        },
    },
});

export default UserInfoForm;
