import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const AutocompleteComponent = props => {
    const { options, id, getOptionLabel, valueKey, onChange, label, defaultValue, margin, ...others } = props;

    return (
        <Autocomplete
            options={options}
            getOptionLabel={getOptionLabel}
            defaultValue={defaultValue}
            id={id}
            onChange={(event, newValue) => {
                onChange(newValue, id);
            }}
            {...others}
            renderInput={params => <TextField {...params} label={label || ''} variant="outlined" margin={margin} />}
        />
    );
};

export default AutocompleteComponent;
