import React from 'react';
import GroupsList from './list/GroupsList';
import {
    Card,
    Tooltip,
    Typography,
    IconButton,
    Divider,
    CardContent,
    CardHeader,
    makeStyles,
    Grid,
    Box,
    Button,
    useTheme,
} from '@material-ui/core';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import SaveIcon from '@material-ui/icons/Save';
import GroupsDetails from './GroupsDetails';
import clsx from 'clsx';
import { Searcher } from 'components/generic/search/Searcher';
import { i18n } from 'i18n';
import { useSelector } from 'react-redux';
import Autocomplete from 'components/generic/autocomplete/Autocomplete';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const activeOptions = [
    {
        value: true,
        label: i18n.actives,
    },
    {
        value: false,
        label: i18n.noActives,
    },
    {
        value: undefined,
        label: i18n.all,
    },
];

const Groups = props => {
    const {
        groups,
        users,
        update,
        create,
        searchText,
        setSearchText,
        setPage,
        active,
        setActive,
        formikRef,
        selectedGroup,
        setSelectedGroupId,
        isLoading,
        isNewGroup,
        setIsNewGroup,
    } = props;

    const { config } = useSelector(state => state.config);

    const theme = useTheme();

    const classes = useStyles();

    const disabledClassname = clsx({
        [classes.disabled]: !(selectedGroup || isNewGroup),
    });

    const loadingClassname = clsx({
        [classes.loading]: isLoading,
    });

    const handleSelectGroup = group => {
        if (!selectedGroup || (selectedGroup !== null && group !== null && selectedGroup?.usersGroupId !== group.usersGroupId)) {
            setSelectedGroupId(group.usersGroupId);
            setIsNewGroup(false);
        }
    };

    const handleIsNew = () => {
        setSelectedGroupId(null);
        setIsNewGroup(true);
    };

    const handleSaveGroup = values => {
        if (config?.launcher?.showAssignmentGroups === undefined || !!config?.launcher?.showAssignmentGroups) {
            if (!isNewGroup) {
                update({ ...selectedGroup, ...values });
            } else {
                if (selectedGroup) create({ ...selectedGroup, ...values });
                else create(values, []);
            }
        } else {
            const finalUser = [];

            const groupUsers = (values.users ?? []).map(u => ({ ...u, ...(users ?? []).find(us => us.userId === u.userId) }));

            groupUsers.forEach(user => {
                const groupFound = user.groups.find(group => group.usersGroupId === selectedGroup.usersGroupId);
                if (!!!groupFound) {
                    const assignedRoles = [];
                    if (user.groups.length > 0) {
                        user.groups[0].roles.forEach(role => {
                            assignedRoles.push({ ...role, isInAllGroups: true });
                        });
                    }
                    assignedRoles.forEach(role => {
                        //Para cada rol ver si está en todos los grupos
                        const groupsWithRole = user.groups.filter(group =>
                            group.roles.find(groupRole => groupRole.roleId === role.roleId)
                        ).length;
                        if (groupsWithRole < user.groups.length) {
                            role.isInAllGroups = false;
                        }
                    });
                    const rolesToAdd = [];
                    assignedRoles.forEach(role => {
                        if (role.isInAllGroups) {
                            rolesToAdd.push({
                                granted: true,
                                roleId: role.roleId,
                                userId: user.userId,
                                usersGroupId: selectedGroup.usersGroupId,
                            });
                        }
                    });
                    user.groups.push({
                        permissiones: [],
                        roles: rolesToAdd,
                        userId: user.userId,
                        usersGroupId: selectedGroup.usersGroupId,
                    });
                    finalUser.push({ permissiones: [], roles: rolesToAdd, userId: user.userId, usersGroupId: selectedGroup.usersGroupId });
                }
            });

            const grUsers = [];

            groupUsers.forEach(element => {
                element.groups.forEach(userToUserGroupDto => {
                    grUsers.push(userToUserGroupDto);
                });
            });

            if (!isNewGroup) {
                update({ ...selectedGroup, ...values, users: grUsers });
            } else {
                if (selectedGroup) create({ ...selectedGroup, ...values, users: grUsers });
                else create(values, []);
            }
        }
    };

    return (
        <div className={`row no-gutters w-100 h-100 ${loadingClassname}`}>
            <Card className="w-100 h-100 d-flex flex-column">
                <CardHeader
                    classes={{ action: classes.cardHeaderContent }}
                    title={i18n.groups}
                    action={
                        <Box display="flex">
                            <Tooltip title={i18n.addGroup}>
                                <Button style={{ border: '1px solid' }} onClick={() => handleIsNew()}>
                                    <GroupAddIcon style={{ marginRight: theme.spacing(1) }} /> {i18n.newGroup}
                                </Button>
                            </Tooltip>
                            <Tooltip title={i18n.save}>
                                <Button
                                    style={{ border: '1px solid', marginLeft: theme.spacing(1) }}
                                    onClick={() => {
                                        if (formikRef.current) formikRef.current.handleSubmit();
                                    }}
                                >
                                    <SaveIcon style={{ marginRight: theme.spacing(1) }} /> {i18n.save}
                                </Button>
                            </Tooltip>
                        </Box>
                    }
                />
                <Divider />
                <CardContent className={`h-100 w-100 row no-gutters flex-grow-1 overflow-auto pb-1`}>
                    <div className="col-3 pl-2 pr-4 h-100 d-flex flex-column" style={{ borderRight: '1px solid lightgrey' }}>
                        <Grid item container xs={12} style={{ flexBasis: 'auto' }}>
                            <Grid item xs={6} style={{ paddingRight: theme.spacing(2) }}>
                                <Searcher
                                    value={searchText}
                                    onChange={value => {
                                        setSearchText(value);
                                        setPage(0);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    margin={'none'}
                                    className={classes.autocomplete}
                                    key={'value'}
                                    getOptionLabel={item => item.label}
                                    value={activeOptions.find(item => item.value === active)}
                                    getOptionSelected={option => {
                                        return option.value === active;
                                    }}
                                    options={activeOptions}
                                    onChange={newValue => {
                                        if (!newValue) return;

                                        setActive(newValue.value);
                                        setPage(0);
                                    }}
                                    label={i18n.status}
                                />
                            </Grid>
                        </Grid>
                        <div className="w-100 mt-2" style={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}>
                            <GroupsList
                                showCount
                                selectedGroup={selectedGroup}
                                setSelectedGroup={group => handleSelectGroup(group)}
                                groups={groups.results ?? []}
                                isLoading={isLoading}
                            />
                        </div>
                        <Grid item container justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
                            <Grid item display={'flex'} alignItems={'center'}>
                                <Typography>
                                    {!!groups && !!groups.results
                                        ? `${groups.page * groups.size + 1} - ${groups.page * groups.size + groups.results.length} de ${
                                              groups.rownum
                                          }`
                                        : ''}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    disabled={groups.page <= 0}
                                    onClick={() => {
                                        if (!!groups && groups.page > 0) {
                                            setSearchText('');
                                            setPage(prev => prev - 1);
                                            setSelectedGroupId(null);
                                        }
                                    }}
                                >
                                    <NavigateBeforeIcon />
                                </IconButton>
                                <IconButton
                                    disabled={groups.page >= groups.pageCount}
                                    onClick={() => {
                                        if (!!groups && groups.page * groups.size + groups.results.length < groups.rownum) {
                                            setPage(prev => prev + 1);
                                            setSelectedGroupId(null);
                                        }
                                    }}
                                >
                                    <NavigateNextIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={`col-9 w-100 h-100 p-3 d-flex flex-column ${disabledClassname}`}>
                        <GroupsDetails
                            newGroup={isNewGroup}
                            users={users}
                            selectedGroup={selectedGroup}
                            saveGroup={handleSaveGroup}
                            setIsNewGroup={setIsNewGroup}
                            formikRef={formikRef}
                        />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default Groups;

const useStyles = makeStyles(() => ({
    disabled: {
        pointerEvents: 'none',
        opacity: 0.4,
    },
    loading: {
        pointerEvents: 'none',
        opacity: 0.7,
    },
    cardHeaderContent: {
        margin: 0,
    },
}));
