import React from 'react';
import { ListItem, List, ListItemText, ListItemAvatar, Avatar, Typography, makeStyles } from '@material-ui/core';
import { formatCompleteUserName } from 'utils/user-utils';
import LdapLinked from 'components/generic/ldap/ldap-linked.component';
import { i18n } from 'i18n';
import { SkeletonList } from 'components/generic/loader/List';

const UsersList = props => {
    const { users, setSelectedUser, selectedUser, isLoading } = props;

    const classes = useStyles();

    if (isLoading) return <SkeletonList numRows={8} />;

    return users ? (
        <List>
            {users.map(user => {
                return (
                    <ListItem
                        className={!user.active ? classes.disabled : null}
                        selected={selectedUser && selectedUser.userId === user.userId}
                        onClick={() => setSelectedUser(user)}
                        key={user.userId}
                        dense
                        button
                    >
                        <ListItemAvatar>
                            {user.image && user.imageType ? (
                                <Avatar src={`data:${user.imageType};base64,${user.image}`} />
                            ) : (
                                <Avatar src={`${process.env.PUBLIC_URL}/assets/USUARIO.svg`} />
                            )}
                        </ListItemAvatar>
                        <ListItemText
                            primary={formatCompleteUserName(user, true)}
                            secondary={
                                <div className="row no-gutters align-items-center">
                                    <Typography className="mr-2">{user.username}</Typography>
                                    {user.authenticationMethods &&
                                    user.authenticationMethods.length > 0 &&
                                    user.authenticationMethods.some(am => am.type === 'LDAP') ? (
                                        <LdapLinked />
                                    ) : null}
                                    {!user.groups?.length ? <NoGroup /> : null}
                                </div>
                            }
                        />
                    </ListItem>
                );
            })}
        </List>
    ) : null;
};

const NoGroup = () => {
    const classes = useStyles();
    return (
        <Avatar variant="rounded" className={classes.warning}>
            {i18n.noGroups}
        </Avatar>
    );
};

export default UsersList;

const useStyles = makeStyles(() => ({
    disabled: {
        opacity: 0.5,
    },
    warning: {
        color: '#ffcc00',
        border: 'solid 2px',
        padding: '2px',
        borderColor: '#ffcc00',
        fontWeight: 'bold',
        width: '50px',
        fontSize: '8px',
        height: '16px',
        background: 'transparent',
    },
}));
