import React from 'react';
import InputPassword from './passwordInput.component';
import { Button, Divider } from '@material-ui/core';
import { Form, Field } from 'formik';
import { i18n } from 'i18n';

const NewPasswordForm = props => {
    return (
        <Form>
            <div className="w-100 h-100">
                <div className="row no-gutters pb-4">
                    <Field
                        variant="outlined"
                        fullWidth
                        label={i18n.newPassword}
                        name="newPassword"
                        autoComplete="false"
                        component={InputPassword}
                    ></Field>
                </div>
                <div className="row no-gutters pb-4">
                    <Field
                        variant="outlined"
                        fullWidth
                        label={i18n.confirmNewPassword}
                        name="confirmNewPassword"
                        autoComplete="false"
                        component={InputPassword}
                    ></Field>
                </div>
                <Divider />
                <div className="row w-100 pt-2">
                    <div className="w-100">
                        <div className="float-right">
                            <Button type="submit">{i18n.accept}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default NewPasswordForm;
