import React from 'react';
import { Formik } from 'formik';
import GroupsDetailsForm from './forms/GroupsDetailsForm';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import * as Yup from 'yup';
import { i18n } from 'i18n';

const GroupsDetails = props => {
    const { formikRef } = props;

    const classes = useStyles();

    const disabledClassname = clsx({
        [classes.disabled]: !(!props.selectedGroup || (props.selectedGroup !== null && props.selectedGroup.active)),
    });

    const initialValues = {
        usersGroupName: '',
        description: '',
        image: '',
        imageType: '',
        active: true,
        users: [],
    };

    const validationSchema = Yup.object({
        usersGroupName: Yup.string().required(i18n.requiredField),
        description: Yup.string(),
    });

    return (
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(false);
                resetForm();
                props.saveGroup(values);
            }}
        >
            {componentProps => <GroupsDetailsForm {...componentProps} {...props} className={disabledClassname} />}
        </Formik>
    );
};

export default GroupsDetails;

const useStyles = makeStyles(() => ({
    disabled: {
        pointerEvents: 'none',
        opacity: 0.4,
    },
}));
