import React from 'react';
import { ListItem, List, ListItemText } from '@material-ui/core';
import { SkeletonList } from 'components/generic/loader/List';

const RolesList = props => {
    const { roles, setSelectedRole, selectedRole, isLoading } = props;

    if (isLoading) return <SkeletonList numRows={8} rowHeight={40} />;

    return roles ? (
        <List>
            {roles.map(role => {
                return (
                    <ListItem
                        selected={selectedRole && selectedRole.roleId === role.roleId}
                        onClick={() => setSelectedRole(role)}
                        key={role.roleId}
                        dense
                        button
                    >
                        <ListItemText primary={role.roleName} />
                    </ListItem>
                );
            })}
        </List>
    ) : null;
};

export default RolesList;
