import * as ActionTypes from './ActionTypes';
import * as authTypes from '../../../model/authTypes';
import axios from 'axios';
import { formatCompleteUserName } from '../../../utils/user-utils';

export const fetchUsersByFilter = filter => (dispatch, getState) => {
    dispatch(usersLoading());
    const config = getState().config.config;
    const fetchUsersByFilterUrl = `${config.AUTH.BASE}/users/search-all`;

    return axios
        .post(fetchUsersByFilterUrl, filter)
        .then(response => response.data)
        .then(users => {
            dispatch(usersLoad(users.sort((a, b) => formatCompleteUserName(a, true).localeCompare(formatCompleteUserName(b, true)))));
        })
        .catch(error => {
            error.message = 'Error ' + error.response.status + ': ' + error.response.statusText;
            dispatch(usersLoadFailed(error.message));
        });
};

export const fetchUsers = () => (dispatch, getState) => {
    dispatch(usersLoading());
    const config = getState().config.config;
    const getUsersURL = `${config.AUTH.BASE}/users`;

    return axios
        .get(getUsersURL)
        .then(response => response.data)
        .then(users => {
            dispatch(usersLoad(users.sort((a, b) => formatCompleteUserName(a, true).localeCompare(formatCompleteUserName(b, true)))));
        })
        .catch(error => {
            error.message = 'Error ' + error.response.status + ': ' + error.response.statusText;
            dispatch(usersLoadFailed(error.message));
        });
};

export const fetchUserGroups = userId => (dispatch, getState) => {
    const config = getState().config.config;
    const getUsersURL = `${config.AUTH.BASE}/users/${userId}/usersgroups`;
    return axios
        .get(getUsersURL)
        .then(response => response.data)
        .then(res => dispatch(userLoadGroups(res)))
        .catch(error => {
            error.message = 'Error ' + error.response.status + ': ' + error.response.statusText;
            dispatch(usersLoadFailed(error.message));
        });
};

export const putUser = user => (dispatch, getState) => {
    if (user !== null) {
        dispatch(usersUpdating());
        const config = getState().config.config;
        const putUserURL = `${config.AUTH.BASE}/users/${user.userId}`;
        // const loggedUserId = getState().users.loggedUser.userId;

        let newUser;
        if (user.authType) {
            switch (user.authType) {
                case authTypes.LOCAL:
                    if (user.newPassword !== '') {
                        newUser = {
                            ...user,
                            authenticationMethods: [
                                ...user.authenticationMethods.filter(am => am.type === authTypes.CODE),
                                {
                                    type: authTypes.LOCAL,
                                    password: user.newPassword,
                                },
                            ],
                        };
                    } else {
                        newUser = { ...user };
                    }
                    break;
                case authTypes.LDAP:
                    newUser = {
                        ...user,
                        authenticationMethods: [
                            ...user.authenticationMethods.filter(am => am.type === authTypes.CODE),
                            {
                                type: authTypes.LDAP,
                                ldapUsername: user.dn,
                            },
                        ],
                    };
                    break;
                default:
                    newUser = {
                        ...user,
                        authenticationMethods: [...user.authenticationMethods.filter(am => am.type === authTypes.CODE)],
                    };
            }
        } else {
            newUser = { ...user };
        }
        return axios
            .put(putUserURL, newUser)
            .then(response => response.data)
            .then(usrRes => {
                const usr = getState().users.loggedUser;
                if (usr !== null && usr.userId === usrRes.userId) {
                    dispatch(userLoggedIn(usrRes));
                }
            })
            .then(() => dispatch(fetchUsers()))
            .then(() => dispatch(usersUpdated()))
            .catch(error => {
                error.message = 'Error ' + error.response.status + ': ' + error.response.statusText;
                dispatch(usersUpdatingFailed(error.message));
            });
    }
};

export const registerUser = user => (dispatch, getState) => {
    if (user !== null) {
        dispatch(usersCreating());
        const config = getState().config.config;
        const postUserUrl = `${config.AUTH.BASE}/users`;

        let newUser = {
            username: user.username,
            groups: [],
            name: user.name,
            firstSurname: user.firstSurname,
            secondSurname: user.secondSurname,
            allowedLogin: user.allowedLogin,
            phoneNumber: user.phoneNumber,
            mobilePhoneNumber: user.mobilePhoneNumber,
            email: user.email,
            image: user.image,
            imageType: user.imageType,
        };
        switch (user.authType) {
            case authTypes.LOCAL:
                newUser = {
                    ...newUser,
                    authenticationMethods: [
                        {
                            type: authTypes.LOCAL,
                            password: user.newPassword,
                        },
                    ],
                };
                break;
            case authTypes.LDAP:
                newUser = {
                    ...newUser,
                    authenticationMethods: [
                        {
                            type: authTypes.LDAP,
                            ldapUsername: user.dn,
                        },
                    ],
                };
                break;
            default:
                newUser = {
                    ...newUser,
                    authenticationMethods: [],
                };
        }

        newUser = {
            ...newUser,
            authenticationMethods: [
                ...newUser.authenticationMethods,
                {
                    type: authTypes.CODE,
                },
            ],
        };

        return axios
            .post(postUserUrl, newUser)
            .then(response => response.data)
            .then(() => dispatch(fetchUsers()))
            .catch(error => {
                if (usernameAlreadyExists(error.response.status)) {
                    error.message = 'Error 409: Ya existe una persona con ese nombre de usuario';
                }
                dispatch(usersCreatingFailed(error.message));
            });
    }
};

function usernameAlreadyExists(status) {
    let alreadyExists = false;
    if (status === 409) {
        alreadyExists = true;
    }
    return alreadyExists;
}

export const userLoading = () => ({
    type: ActionTypes.USER_LOADING,
});

export const usersLoading = () => ({
    type: ActionTypes.USERS_LOADING,
});

export const usersLoad = loadedUsers => ({
    type: ActionTypes.LOAD_USERS,
    payload: loadedUsers,
});

export const usersLoadFailed = errMess => ({
    type: ActionTypes.USERS_LOAD_FAILED,
    payload: errMess,
});

export const userLoggedIn = user => ({
    type: ActionTypes.USER_LOGGED_IN,
    payload: user,
});

export const userLoadGroups = groups => ({
    type: ActionTypes.LOAD_USER_GROUPS,
    payload: groups,
});
export const usersUpdating = () => ({
    type: ActionTypes.USERS_UPDATING,
});

export const usersUpdatingFailed = errMess => ({
    type: ActionTypes.USERS_UPDATING_FAILED,
    payload: errMess,
});

export const usersUpdated = () => ({
    type: ActionTypes.USERS_UPDATED,
});

export const usersCreating = () => ({
    type: ActionTypes.USERS_CREATING,
});

export const usersCreatingFailed = errMess => ({
    type: ActionTypes.USERS_CREATING_FAILED,
    payload: errMess,
});

export const usersCreated = () => ({
    type: ActionTypes.USERS_CREATED,
});
