import React from 'react';

import { i18n } from '../i18n';

import { AppBar, getEmiMenuItems } from '@snc/app-bar';
import { connect } from 'react-redux';
import { useLocation, NavLink } from 'react-router-dom';

import { Routes } from '../utils/routes';

const mapStateToProps = state => {
    return {
        loggedUser: state.users.loggedUser,
        config: state.config.config,
        users: state.users,
        apps: state.apps,
        roles: state.roles.roles,
        permissions: state.permissions.permissions,
    };
};

const getMenu = () => [
    {
        value: Routes.administration,
        tag: <NavLink to={Routes.administration}>{i18n.administration}</NavLink>,
    },
];

function Header(props) {
    const { pathname } = useLocation();
    const { config } = props;

    return (
        <AppBar
            menus={getMenu()}
            logo={{
                src: `${process.env.PUBLIC_URL}/${'assets/header-logo-emi.svg'}`,
                src2: `${process.env.PUBLIC_URL}/${'assets/header-logo-launcher.svg'}`,
            }}
            selected={pathname}
            collapse="xs"
            loginUrl={config.LOGIN_URL}
            userApiUrl={`${config.AUTH.BASE}/users/search-one`}
            userMenuItems={getEmiMenuItems(config)}
            revokeRefreshTokenUrl={`${config.AUTH.BASE}/refresh-token/revoke`}
        />
    );
}

export default connect(mapStateToProps, null)(Header);
