import React, { useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import PropTypes from 'prop-types';
import { i18n } from 'i18n';

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const SncFileUpload = props => {
    const [files, setFiles] = useState([]);
    return (
        <div className="h-100">
            <FilePond
                labelIdle={`${i18n.dragFile} <span class="filepond--label-action">${i18n.explore}</span>`}
                files={files}
                onupdatefiles={setFiles}
                {...props}
                credits={false}
            ></FilePond>
        </div>
    );
};

SncFileUpload.propTypes = {
    acceptedFileTypes: PropTypes.arrayOf(PropTypes.string),
    instantUpload: PropTypes.bool,
    server: PropTypes.object,
    labelIdle: PropTypes.string,
    labelInvalidField: PropTypes.string,
    labelFileWaitingForSize: PropTypes.string,
    labelFileSizeNotAvailable: PropTypes.string,
    labelFileLoading: PropTypes.string,
    labelFileLoadError: PropTypes.string,
    labelFileProcessing: PropTypes.string,
    labelFileProcessingComplete: PropTypes.string,
    labelFileProcessingAborted: PropTypes.string,
    labelFileProcessingError: PropTypes.string,
    labelFileProcessingRevertError: PropTypes.string,
    labelFileRemoveError: PropTypes.string,
    labelTapToCancel: PropTypes.string,
    labelTapToRetry: PropTypes.string,
    labelTapToUndo: PropTypes.string,
    labelButtonRemoveItem: PropTypes.string,
    labelButtonAbortItemLoad: PropTypes.string,
    labelButtonRetryItemLoad: PropTypes.string,
    labelButtonAbortItemProcessing: PropTypes.string,
    labelButtonUndoItemProcessing: PropTypes.string,
    labelButtonRetryItemProcessing: PropTypes.string,
    labelButtonProcessItem: PropTypes.string,
    allowDrop: PropTypes.bool,
    allowBrowse: PropTypes.bool,
    allowMultiple: PropTypes.bool,
    maxFiles: PropTypes.number,
    onerror: PropTypes.func,
    onwarning: PropTypes.func,
    onaddfilestart: PropTypes.func,
    onaddfileprogress: PropTypes.func,
    onaddfile: PropTypes.func,
    onprocessfilestart: PropTypes.func,
    onprocessfileprogress: PropTypes.func,
    onprocessfileabort: PropTypes.func,
    onprocessfilerevert: PropTypes.func,
    onprocessfile: PropTypes.func,
    onprocessfiles: PropTypes.func,
    onremovefile: PropTypes.func,
    onpreparefile: PropTypes.func,
    onupdatefiles: PropTypes.func,
    onactivatefile: PropTypes.func,
    beforeDropFile: PropTypes.func,
    beforeAddFile: PropTypes.func,
    beforeRemoveFile: PropTypes.func,
};

export default SncFileUpload;
