import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Groups from './Groups';
import { ActionProgressReport } from '@snc/ui';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { i18n } from 'i18n';
import { removeDuplicates } from 'utils/generalUtils';

export default function GroupsWrapper() {
    const { config } = useSelector(state => state.config);

    const showAssignmentPerGroup = config?.launcher?.showAssignmentGroups === undefined || !!config?.launcher?.showAssignmentGroups;

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [isNewGroup, setIsNewGroup] = useState(false);

    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [size] = useState(50);
    const [active, setActive] = useState(true);

    const formikRef = useRef(null);

    const filter = React.useMemo(
        () => ({
            content: {
                active,
                ...(!!searchText?.length && { searchText }),
            },
            page,
            size,
        }),
        [active, page, searchText, size]
    );

    const {
        isLoading: isLoadingUsers,
        isSuccess: isSuccessUsers,
        data: users,
    } = useQuery('getUsers', () => axios.get(`${config.AUTH.BASE}/users`));

    const {
        isLoading: isLoadingGroups,
        isSuccess: isSuccessGroups,
        data: searchedGroups,
    } = useQuery(['groupsSearchAll', filter], () => axios.post(`${config.AUTH.BASE}/usersgroups/search-all`, filter));

    const { mutate: update, isLoading: isLoadingUpdate } = useMutation(
        async updateGroup => {
            const response = await axios.put(`${config.AUTH.BASE}/usersgroups/${updateGroup.usersGroupId}`, updateGroup);

            if (showAssignmentPerGroup) return response;

            const group = (searchedGroups?.data?.results ?? []).find(group => group.usersGroupId === updateGroup.usersGroupId);

            const oldUserIds = (group?.users ?? []).map(user => user.userId);
            const currentUserIds = (updateGroup?.users ?? []).map(user => user.userId);

            const addedUserIds = removeDuplicates(currentUserIds.filter(userId => !oldUserIds.includes(userId)));

            if (!!addedUserIds.length) await axios.post(`${config.AUTH.BASE}/users/unify-roles`, addedUserIds);

            return response;
        },
        {
            onSuccess: ({ data: updatedGroup }) => {
                enqueueSnackbar(i18n.updated, { variant: 'success' });
                queryClient.invalidateQueries('getUsers');
                queryClient.invalidateQueries('groupsSearchAll');

                setIsNewGroup(false);
            },
            onError: () => {
                enqueueSnackbar(i18n.error, { variant: 'error' });
            },
        }
    );

    const { mutate: create, isLoading: isLoadingCreate } = useMutation(
        groupInfo => {
            return axios.post(`${config.AUTH.BASE}/usersgroups`, groupInfo);
        },
        {
            onSuccess: ({ data: newGroup }) => {
                enqueueSnackbar(i18n.created, { variant: 'success' });
                queryClient.invalidateQueries('getUsers');
                queryClient.invalidateQueries('groupsSearchAll');

                setSelectedGroupId(newGroup.usersGroupId);
                setSearchText(newGroup.usersGroupName);
                setPage(0);
                setIsNewGroup(false);
            },
            onError: () => {
                enqueueSnackbar(i18n.error, { variant: 'error' });
            },
        }
    );

    const selectedGroup = React.useMemo(
        () => (searchedGroups?.data?.results ?? []).find(group => group.usersGroupId === selectedGroupId) ?? null,
        [searchedGroups, selectedGroupId]
    );

    const isLoading = isLoadingGroups || isLoadingUsers;

    return (
        <div className="row no-gutters w-100 h-100">
            <div className="row no-gutters w-100 h-100 position-relative">
                {(isLoadingCreate || isLoadingUpdate || isLoading) && <ActionProgressReport />}
                <Groups
                    users={isSuccessUsers ? users.data : []}
                    groups={isSuccessGroups ? searchedGroups.data : []}
                    update={update}
                    create={create}
                    isLoading={isLoading}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    setPage={setPage}
                    active={active}
                    setActive={setActive}
                    formikRef={formikRef}
                    selectedGroup={selectedGroup}
                    setSelectedGroupId={setSelectedGroupId}
                    isNewGroup={isNewGroup}
                    setIsNewGroup={setIsNewGroup}
                />
            </div>
        </div>
    );
}
