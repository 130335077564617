import * as React from 'react';
import debounce from 'lodash.debounce';
import { Searcher as SearcherUI } from '@snc/ui';

export const Searcher = props => {
    const { value, onChange } = props;

    const [tempText, setTempText] = React.useState(value ?? '');

    React.useEffect(() => {
        if (tempText !== value) setTempText(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const debouncedSearch = React.useCallback(
        debounce(value => {
            onChange(value);
        }, 500),
        []
    );

    return (
        <SearcherUI
            value={tempText}
            onChange={value => {
                setTempText(value);
                debouncedSearch(value);
            }}
        />
    );
};
