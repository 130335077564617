import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { i18n } from 'i18n';
import { useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { userLoggedIn } from 'redux/administration/users/ActionCreators';
import { ActionProgressReport } from '@snc/ui';
import { Avatar, Box, Card, CardContent, Divider, Grid, makeStyles, Button, Tooltip, useTheme, CardHeader } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import PersonIcon from '@material-ui/icons/Person';
import SaveIcon from '@material-ui/icons/Save';
import SncFileUpload from 'components/generic/file-upload/snc-file-upload.component';
import { imageToB64 } from 'utils/imageUtils';

export default function Profile(props) {
    const { enqueueSnackbar } = useSnackbar();

    const { config } = useSelector(state => state.config);
    const { loggedUser } = useSelector(state => state.users);

    const classes = useStyles();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const [editable] = React.useState(false); //TODO: By default the users does not have edit users permissions so until we find a solution this is a read-only form

    const formikRef = React.useRef();

    const validationSchema = Yup.object({
        name: Yup.string().required(i18n.requiredField),
        firstSurname: Yup.string(),
        secondSurname: Yup.string(),
        phoneNumber: Yup.string(),
        mobilePhoneNumber: Yup.string(),
        email: Yup.string(),
        image: Yup.string(),
        imageType: Yup.string(),
    });

    const initialValues = {
        name: loggedUser.name ?? '',
        firstSurname: loggedUser.firstSurname ?? '',
        secondSurname: loggedUser.secondSurname ?? '',
        phoneNumber: loggedUser.phoneNumber ?? '',
        mobilePhoneNumber: loggedUser.mobilePhoneNumber ?? '',
        email: loggedUser.email ?? '',
        image: loggedUser.image ?? '',
        imageType: loggedUser.imageType ?? '',
    };

    const { mutate: update, isLoading } = useMutation(
        values => axios.put(`${config.AUTH.BASE}/users/${loggedUser.userId}`, { ...loggedUser, ...values }),
        {
            onSuccess: response => {
                enqueueSnackbar(i18n.updated, { variant: 'success' });
                queryClient.invalidateQueries('usersSearchAll');
                dispatch(userLoggedIn(response.data));

                if (formikRef.current) {
                    formikRef.current.setValues({
                        name: response.data.name ?? '',
                        firstSurname: response.data.firstSurname ?? '',
                        secondSurname: response.data.secondSurname ?? '',
                        phoneNumber: response.data.phoneNumber ?? '',
                        mobilePhoneNumber: response.data.mobilePhoneNumber ?? '',
                        email: response.data.email ?? '',
                        image: response.data.image ?? '',
                        imageType: response.data.imageType ?? '',
                    });
                    formikRef.current.setSubmitting(false);
                }
            },
            onError: () => {
                enqueueSnackbar(i18n.error, { variant: 'error' });
            },
        }
    );

    return (
        <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                update(values);
            }}
            component={componentProps => (
                <Form noValidate className={classes.form}>
                    {isLoading && <ActionProgressReport />}
                    <UserForm {...componentProps} {...props} editable={editable} />
                </Form>
            )}
        />
    );
}

const UserForm = props => {
    const { setFieldValue, values, handleSubmit, editable } = props;

    const classes = useStyles();
    const theme = useTheme();

    return (
        <Card className={classes.card}>
            <CardHeader
                classes={{ action: classes.cardHeaderContent }}
                title={i18n.myProfile}
                action={
                    editable ? (
                        <Box display="flex">
                            <Tooltip title={i18n.save}>
                                <Button
                                    disabled={!editable}
                                    style={{ border: '1px solid', marginLeft: theme.spacing(1) }}
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >
                                    <SaveIcon style={{ marginRight: theme.spacing(1) }} /> {i18n.save}
                                </Button>
                            </Tooltip>
                        </Box>
                    ) : null
                }
            />
            <Divider />
            <CardContent className={`${classes.cardContent} ${!editable ? classes.disabled : ''}`}>
                <Grid container className={classes.grid}>
                    <Grid item xs={12} md={5}>
                        <Box height={1} width={1} display="flex" flexDirection="column">
                            <Box width={1} flexGrow={1} display="flex" justifyContent="center" alignItems="center">
                                <Avatar
                                    className="img-responsive fit-image"
                                    src={values.image ? `data:${values.imageType};base64,${values.image}` : undefined}
                                    style={{ height: 240, width: 240, marginBottom: theme.spacing(3) }}
                                >
                                    <PersonIcon style={{ height: 80, width: 80 }} />
                                </Avatar>
                            </Box>
                            {editable && (
                                <Box width={1}>
                                    <SncFileUpload
                                        imagePreviewMaxHeight={100}
                                        className={`w-100 ${classes.upload}`}
                                        acceptedFileTypes={['image/*']}
                                        maxFileSize={'1MB'}
                                        allowMultiple={false}
                                        instantUpload
                                        server={{
                                            process: (fieldName, file, metadata, load) => {
                                                imageToB64(file).then(b64 => {
                                                    setFieldValue('image', b64.substr(b64.indexOf(',') + 1));
                                                    setFieldValue('imageType', b64.substring(b64.indexOf(':') + 1, b64.indexOf(';')));
                                                    load(b64);
                                                });
                                            },
                                            load: (source, load) => {
                                                fetch(source)
                                                    .then(res => {
                                                        return res.blob();
                                                    })
                                                    .then(blob => {
                                                        return load(blob);
                                                    });
                                            },
                                        }}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={7} style={{ height: '100%' }}>
                        <Grid container className={classes.grid} spacing={2} direction="column" wrap="nowrap">
                            <Grid item>
                                <Field
                                    name="name"
                                    required
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label={i18n.name}
                                    component={TextField}
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    name="firstSurname"
                                    required
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label={i18n.firstSurname}
                                    component={TextField}
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    name="secondSurname"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label={i18n.secondSurname}
                                    component={TextField}
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    name="phoneNumber"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label={i18n.telephone}
                                    component={TextField}
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    name="mobilePhoneNumber"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    label={i18n.mobile}
                                    component={TextField}
                                />
                            </Grid>
                            <Grid item>
                                <Field name="email" type="text" variant="outlined" fullWidth label={i18n.email} component={TextField} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const useStyles = makeStyles(theme => ({
    form: {
        height: '100%',
        width: '100%',
        display: 'flex',
    },
    grid: {
        height: '100%',
        width: '100%',
        margin: 0,
    },
    cardHeaderContent: {
        margin: 0,
    },
    card: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        width: '100%',
        padding: theme.spacing(1),
    },
    disabled: {
        pointerEvents: 'none',
    },
}));
