import { Box, Grid, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export const SkeletonList = props => {
    const { numRows = 1, rowHeight = 50 } = props;

    const classes = useStyles();

    return (
        <Box width={1} height={1} pt={2}>
            <Grid container direction="column" spacing={1} className={classes.grid}>
                {[...Array(numRows)].map((_, index) => (
                    <Grid key={index} item style={{ display: 'flex', height: rowHeight }}>
                        <Skeleton classes={{ root: classes.skeleton }} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

const useStyles = makeStyles({
    grid: {
        height: '100%',
        width: '100%',
        margin: 0,
    },
    skeleton: {
        height: '100%',
        width: '100%',
        transform: 'unset !important',
    },
});
