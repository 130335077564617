import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { CheckboxWithLabel } from 'formik-material-ui';
import { Field } from 'formik';
import { i18n } from 'i18n';

const RoleInfoForm = props => {
    const classes = useStyles();

    return (
        <div className="row no-gutters p-1 w-100">
            <div className="w-100 h-100 d-flex flex-column">
                <div className="row no-gutters mb-3">
                    <Typography variant="h4" gutterBottom>
                        {i18n.rolData}
                    </Typography>
                </div>
                <div className="row no-gutters">
                    <div className="col-12 col-sm-12 col-md-12 col-xl-6 p-1">
                        <Field name="roleName" required type="text" variant="outlined" fullWidth label={i18n.name} component={TextField} />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-xl-6 p-1">
                        <Field name="description" type="text" variant="outlined" fullWidth label={i18n.description} component={TextField} />
                    </div>
                    <div className={`col-12 col-sm-12 col-md-12 col-xl-6 p-1 d-flex  align-items-center ${classes.checkbox}`}>
                        <Field
                            style={{ margin: 0 }}
                            name="active"
                            component={CheckboxWithLabel}
                            Label={{ label: i18n.active }}
                            type="checkbox"
                            color="primary"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    checkbox: {
        '& .MuiFormControlLabel-root': {
            marginBottom: 0,
        },
    },
}));

export default RoleInfoForm;
