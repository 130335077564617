import React from 'react';
import GroupsInfoForm from './GroupsInfoForm';
import { Divider } from '@material-ui/core';
import GroupsDetailsList from '../list/GroupsDetailsList';
import { Form } from 'formik';

const GroupsDetailsForm = props => {
    const { setValues, selectedGroup, newGroup, resetForm } = props;

    React.useEffect(() => {
        if (selectedGroup && !newGroup) {
            setValues({
                usersGroupName: selectedGroup.usersGroupName ?? '',
                description: selectedGroup.description ?? '',
                image: selectedGroup.image ?? '',
                imageType: selectedGroup.imageType ?? '',
                active: selectedGroup.active ?? true,
                users: selectedGroup.users ?? [],
            });
        }

        if ((newGroup && !selectedGroup) || (!newGroup && !selectedGroup)) {
            resetForm();
        }
    }, [newGroup, resetForm, selectedGroup, setValues]);

    return (
        <Form className={`w-100 h-100 d-flex flex-column`} noValidate>
            <div className="row no-gutters mb-3">
                <div className="col-12">
                    <GroupsInfoForm {...props} />
                </div>
            </div>
            <Divider />
            <div className="row no-gutters p-2 mt-3 flex-grow-1 overflow-hidden">
                <GroupsDetailsList {...props} />
            </div>
        </Form>
    );
};

export default GroupsDetailsForm;
