const h2r = hex => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};

export const getContrastText = hexColor => {
    if (hexColor) {
        if (hexColor === 'transparent' || hexColor.toLowerCase() === '#ffffff') {
            return 'default';
        }

        // If a leading # is provided, remove it
        if (hexColor.slice(0, 1) === '#') {
            hexColor = hexColor.slice(1);
        }

        // If a three-character hexcode, make six-character
        if (hexColor.length === 3) {
            hexColor = hexColor
                .split('')
                .map(hex => hex + hex)
                .join('');
        }

        const [r, g, b] = h2r(hexColor);

        // Get YIQ ratio
        const yiq = (r * 299 + g * 587 + b * 114) / 1000;

        // Check contrast
        return yiq >= 128 ? 'black' : 'white';
    }
    return 'default';
};
