import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import AssignmentPerGroup from './AssignmentPerGroup';
import AssignmentAllGroups from './AssignmentAllGroups';
import { ActionProgressReport } from '@snc/ui';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { i18n } from 'i18n';

export const AssignmentContext = React.createContext();

export function AssignmentProvider(props) {
    const { children } = props;

    const { config } = useSelector(state => state.config);

    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);

    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [size] = useState(50);
    const [active, setActive] = useState(true);

    React.useEffect(() => {
        if (selectedUser && !!selectedUser?.groups?.length) {
            const selGroup = selectedUser.groups.find(group => group.usersGroupId === selectedGroup?.usersGroupId);

            if (selGroup) setSelectedGroup(selGroup);
            else setSelectedGroup(selectedUser.groups[0]);
        } else setSelectedGroup(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUser]);

    const filter = React.useMemo(
        () => ({
            content: {
                active,
                ...(!!searchText?.length && { searchText }),
            },
            page,
            size,
        }),
        [active, page, searchText, size]
    );

    const {
        isLoading: isLoadingUsers,
        isSuccess: isSuccessUsers,
        data: users,
    } = useQuery(['usersSearchAll', filter], () => axios.post(`${config.AUTH.BASE}/users/search-all`, filter));

    const {
        isLoading: isLoadingRoles,
        isSuccess: isSuccessRoles,
        data: roles,
    } = useQuery(['roles'], () => axios.get(`${config.AUTH.BASE}/roles`));

    const {
        isLoading: isLoadingGroups,
        isSuccess: isSuccessGroups,
        data: groups,
    } = useQuery(['groups', filter], () => axios.get(`${config.AUTH.BASE}/usersgroups`));

    const { mutate: update, isLoadingUpdate } = useMutation(
        userInfo => {
            return axios.put(`${config.AUTH.BASE}/users/${userInfo.userId}`, userInfo);
        },
        {
            onSuccess: response => {
                enqueueSnackbar(i18n.updated, { variant: 'success' });
                setSelectedUser(response.data);
                queryClient.invalidateQueries('usersSearchAll');
            },
            onError: () => {
                enqueueSnackbar(i18n.error, { variant: 'error' });
            },
        }
    );

    return (
        <AssignmentContext.Provider
            value={{
                users: isSuccessUsers ? users.data : [],
                selectedUser,
                setSelectedUser,
                selectedGroup,
                setSelectedGroup,
                roles: isSuccessRoles ? roles.data : [],
                groups: isSuccessGroups ? groups.data ?? [] : [],
                update,
                searchText,
                setSearchText,
                setPage,
                active,
                setActive,
                isLoadingUsers,
                isLoadingRoles,
                isLoadingGroups,
            }}
        >
            {isLoadingUpdate && <ActionProgressReport />}
            {children}
        </AssignmentContext.Provider>
    );
}

export const useAssignment = () => {
    return useContext(AssignmentContext);
};

export function AssignmentWrapper() {
    const { config } = useSelector(state => state.config);

    const showAssignmentPerGroup = config?.launcher?.showAssignmentGroups === undefined || !!config?.launcher?.showAssignmentGroups;

    return (
        <AssignmentProvider>
            <div className="row no-gutters w-100 h-100">{showAssignmentPerGroup ? <AssignmentPerGroup /> : <AssignmentAllGroups />}</div>
        </AssignmentProvider>
    );
}
