import React from 'react';
import { IconButton, Grid, Typography, Card, CardHeader, Divider, CardContent, makeStyles, useTheme } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { i18n } from 'i18n';
import Autocomplete from 'components/generic/autocomplete/Autocomplete';
import { Searcher } from 'components/generic/search/Searcher';
import UsersList from '../users/list/UsersList';
import { useAssignment } from './AssignmentWrapper';
import { SkeletonList } from 'components/generic/loader/List';

const activeOptions = [
    {
        value: true,
        label: i18n.actives,
    },
    {
        value: false,
        label: i18n.noActives,
    },
    {
        value: undefined,
        label: i18n.all,
    },
];

export default function AssignmentUsersCard() {
    const classes = useStyles();
    const theme = useTheme();

    const { users, selectedUser, setSelectedUser, searchText, setSearchText, setPage, active, setActive, isLoadingUsers } = useAssignment();

    return (
        <Card style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <CardHeader title={i18n.users} />
            <Divider />
            <CardContent
                className="pb-1"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%',
                    flexGrow: 1,
                    overflow: 'auto',
                }}
            >
                <Grid item container xs={12} style={{ flexBasis: 'auto' }}>
                    <Grid item xs={6} style={{ paddingRight: '16px' }}>
                        <Searcher value={searchText} onChange={setSearchText} />
                    </Grid>
                    <Grid item xs={6} style={{ paddingRight: '8px' }}>
                        <Autocomplete
                            margin={'none'}
                            className={classes.autocomplete}
                            key={'value'}
                            getOptionLabel={item => item.label}
                            value={activeOptions.find(item => item.value === active)}
                            getOptionSelected={option => option.value === active}
                            options={activeOptions}
                            onChange={newValue => {
                                if (!newValue) return;

                                setActive(newValue.value);
                                setPage(0);
                            }}
                            label={i18n.status}
                        />
                    </Grid>
                </Grid>
                <div
                    className="w-100 overflow-auto"
                    style={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden', width: '100%', marginTop: theme.spacing(1) }}
                >
                    {isLoadingUsers ? (
                        <SkeletonList numRows={8} />
                    ) : (
                        <UsersList
                            selectedUser={selectedUser}
                            setSelectedUser={user => {
                                setSelectedUser(user);
                            }}
                            users={users?.results ?? []}
                        />
                    )}
                </div>
                <Grid item container justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
                    <Grid item display={'flex'} alignItems={'center'}>
                        <Typography>
                            {!!users && !!users.results
                                ? `${users.page * users.size + 1} - ${users.page * users.size + users.results.length} de ${users.rownum}`
                                : ''}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton
                            disabled={users.page <= 0}
                            onClick={() => {
                                if (!!users && users.page > 0) {
                                    setPage(prev => prev - 1);
                                }
                            }}
                        >
                            <NavigateBeforeIcon />
                        </IconButton>
                        <IconButton
                            disabled={users.page >= users.pageCount}
                            onClick={() => {
                                if (!!users && users.page * users.size + users.results.length < users.rownum) {
                                    setPage(prev => prev + 1);
                                }
                            }}
                        >
                            <NavigateNextIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

const useStyles = makeStyles(() => ({
    disabled: {
        pointerEvents: 'none',
        opacity: 0.4,
    },
    cardHeaderContent: {
        margin: 0,
    },
    autocomplete: {
        margin: 0,
    },
}));
