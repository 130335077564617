import React from 'react';
import { Form } from 'formik';
import { Divider } from '@material-ui/core';
import RoleInfoForm from './RoleInfoForm';
import RolesManagement from './RolesManagement';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import axios from 'axios';

const RoleDetailsForm = props => {
    const { selectedRole, setValues, resetForm, newRole, selectedAppId, setSelectedAppId, selectedModule, setSelectedModule } = props;

    const { config } = useSelector(state => state.config);

    const { data: modulesData } = useQuery(
        ['modules', selectedAppId],
        () => axios.get(`${config.AUTH.BASE}/applications/${selectedAppId}`),
        {
            enabled: !!selectedAppId && selectedAppId > 0,
            refetchOnMount: false,
        }
    );

    const modules = React.useMemo(() => {
        return modulesData?.data?.modules ?? [];
    }, [modulesData]);

    React.useEffect(() => {
        if (selectedRole && !newRole) {
            setValues({
                roleName: selectedRole?.roleName ?? '',
                description: selectedRole?.description ?? '',
                permissions: selectedRole?.permissions ?? [],
                active: selectedRole?.active ?? true,
            });
        }
        if (newRole) {
            resetForm();
        }
    }, [newRole, resetForm, selectedRole, setValues]);

    return (
        <Form className="w-100 h-100 d-flex flex-column" noValidate>
            <RoleInfoForm {...props} />
            <div className="mb-3" />
            <Divider />
            <div className="mb-3" />
            <RolesManagement
                {...props}
                modules={modules}
                selectedAppId={selectedAppId}
                setSelectedAppId={setSelectedAppId}
                selectedModule={selectedModule}
                setSelectedModule={setSelectedModule}
            />
        </Form>
    );
};

export default RoleDetailsForm;
