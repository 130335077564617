import React from 'react';
import clsx from 'clsx';
import { ButtonBase, Button, Divider, Typography, Paper, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getContrastText } from 'utils/colorUtils';

function Home(props) {
    const { links } = props;

    const apps = [...links].filter(link => !link.isExternal);
    const externalLinks = [...links].filter(link => link.isExternal);

    return (
        <div className="h-100 limited-width-1200 pt-4 pl-3 pr-3 pb-3 d-flex flex-column">
            <div className="w-100 pt-2 mb-4 flex-grow-1">
                <div className="row">
                    {apps.map(link => (
                        <div key={link.id} className="col-12 col-sm-6 col-md-4 col-xl-3 pt-4">
                            <AppContent link={link} />
                        </div>
                    ))}
                </div>
            </div>

            {externalLinks && externalLinks.length > 0 && (
                <div className="w-100">
                    <div className="row">
                        <div className="col-12">
                            <Divider />
                        </div>
                    </div>

                    <div className="row pb-4" style={{ maxHeight: '400px' }}>
                        {externalLinks.map(link => {
                            return (
                                <div key={link.id} className="col-6 col-sm-4 col-md-3 col-xl-2 pt-4">
                                    <AppContent link={link} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}

function AppContent(props) {
    const { linkUrl, linkVideo, active, isExternal, image, sublinks, contrastImage } = props.link;

    const videoClass = `link-${props.link.id}`;

    const showVideo = !!linkVideo && linkVideo !== '';

    const classes = useStyles({ videoClass, active });

    const imageVideo = contrastImage && showVideo ? contrastImage : image;

    return (
        <Box>
            <Paper
                style={{
                    borderRadius: '12px',
                    boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#fff',
                    position: 'relative',
                }}
                className={clsx({
                    [classes.disabled]: !active,
                    [classes.link]: active,
                })}
            >
                {showVideo && <video className={`${classes.video} video`} src={linkVideo} muted autoPlay loop />}
                <ButtonBase className="w-100" disabled={!active} style={{ borderRadius: '12px' }}>
                    <a className="w-100" href={linkUrl} target={isExternal ? '_blank' : ''}>
                        <div className="w-100" style={{ borderRadius: '12px' }}>
                            <svg width="100%" height="100%" viewBox="0 0 900 450" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <rect id="rounded-image-rect" x="0" y="0" width="900" height="500" />
                                    <clipPath id="rounded-image-clip">
                                        <use href="#rounded-image-rect" />
                                    </clipPath>
                                </defs>

                                <image
                                    href={image ? `data:image/${image.imageType};base64,${image.content}` : ''}
                                    x="50"
                                    y={isExternal ? '25' : '165'}
                                    width="800"
                                    height={isExternal ? '400' : '125'}
                                    preserveAspectRatio="xMidYMid meet"
                                    clipPath="url(#rounded-image-clip)"
                                    style={{ boxShadow: '0 0 10px', opacity: 0.86, padding: '20px' }}
                                    className="logo-image"
                                />

                                <image
                                    href={imageVideo ? `data:image/${imageVideo.imageType};base64,${imageVideo.content}` : ''}
                                    x="50"
                                    y={isExternal ? '25' : '165'}
                                    width="800"
                                    height={isExternal ? '400' : '125'}
                                    preserveAspectRatio="xMidYMid meet"
                                    clipPath="url(#rounded-image-clip)"
                                    style={{ boxShadow: '0 0 10px', padding: '20px', visibility: 'hidden' }}
                                    className="logo-contrast-image"
                                />
                            </svg>
                        </div>
                    </a>
                </ButtonBase>
            </Paper>

            {[...sublinks]
                .sort((a, b) => a.position - b.position)
                .map(sublink => (
                    <SubLink key={sublink.id} {...sublink} />
                ))}
        </Box>
    );
}

const SubLink = props => {
    const { active, name, sublinkUrl, color, contrastColor } = props;

    const classes = useStyles({ color, contrastColor });

    return (
        <Box style={{ width: '100%', paddingTop: '12px' }}>
            <Button
                variant="outlined"
                onClick={() => {
                    window.location = sublinkUrl;
                }}
                style={{
                    backgroundColor: '#fff',
                    width: '100%',
                    textAlign: 'left',
                    border: 'none',
                    borderRadius: '0px 12px 12px 0px',
                    boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.1)',
                    color: '#000',
                    borderLeft: `4px ${color} solid`,
                    opacity: 1,
                }}
                disabled={!active}
                className={clsx({
                    [classes.sublinkDisabled]: !active,
                    [classes.sublink]: true,
                })}
            >
                <Typography align="left" style={{ textTransform: 'capitalize', width: '100%', fontSize: '14px' }}>
                    {name}
                </Typography>
            </Button>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    disabled: {
        backgroundColor: '#f4f4f4 !important',
    },
    sublinkDisabled: {
        color: '#000',
        backgroundColor: '#f4f4f4 !important',
    },
    video: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: 12,
        visibility: 'hidden',
    },
    link: {
        '&:hover': {
            transform: 'scale(1.02)',
            '& .video': {
                visibility: 'visible',
                animation: 'fadeIn 1s',
            },

            '& .logo-image': {
                visibility: 'hidden', //? INFO: remove this line to enable animation
                animation: 'fadeOutVisiblity 0.3s',
            },

            '& .logo-contrast-image': {
                visibility: 'visible !important',
            },

            '& .logo-video': {
                visibility: 'visible',
            },
        },
    },
    sublink: {
        position: 'relative',
        overflow: 'hidden',
        transition: 'color 2s ease',
        background: 'transparent',
        '& .MuiTypography-root': {
            zIndex: 10,
            fontWeight: '400',
        },
        '&::before': {
            content: "''",
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: '#e5e5e5',
            transform: 'translateX(-100%)',
            transition: 'transform 2s ease, opacity 2s ease',
            zIndex: 0,
        },
        '&:hover': {
            '& .MuiTypography-root': {
                zIndex: 10,
                color: ({ color, contrastColor }) => (color || contrastColor ? getContrastText(contrastColor || color) : 'inherit'),
            },
            '&::before': {
                transform: 'translateX(0)',
                opacity: 1,
                background: ({ color }) => color,
                //backgroundImage: `url("${process.env.PUBLIC_URL}/${config.LOGIN.BG_IMG}") !important`,
                color: ({ color, contrastColor }) => (color || contrastColor ? getContrastText(contrastColor || color) : 'inherit'),
            },
        },
    },
}));

export default Home;
