import { MODULES, getModuleByName } from 'utils/module-utils';
import { hasPerm } from 'model/loggedUser';

export const getModulePermissions = (usersGroups, moduleName, modules, roles, permissions, applicationId) => {
    const userRoles = usersGroups.reduce((acc, userGroup) => {
        return [...acc, ...userGroup.roles];
    }, []);

    const roleIdMapping = userRoles.map(ur => ur.roleId);
    const rolesInfo = roles.filter(r => roleIdMapping.includes(r.roleId));

    let userPermissions = rolesInfo.reduce((acc, role) => {
        return [...acc, ...role.permissions];
    }, []);

    const mod = getModuleByName(moduleName, modules, applicationId) || {};

    if (permissions && permissions.length > 0) {
        userPermissions = userPermissions.map(up => permissions.find(p => p.permissionId === up.permissionId));
    }

    return userPermissions.filter(permission => permission.moduleId === mod.moduleId);
};

export const getPermissions = (moduleName, permissions) => {
    return {
        canView: hasPerm(MODULES[moduleName].perms.view, permissions),
        canEdit: hasPerm(MODULES[moduleName].perms.edit, permissions),

        // Permissions for tasks menu
        canEditCorrective: hasPerm(MODULES[moduleName].perms.editCorrective, permissions),
        canImputate: hasPerm(MODULES[moduleName].perms.imputate, permissions),

        canViewProfiles: hasPerm(MODULES[moduleName].perms.viewProfiles, permissions),
    };
};

export const canViewMenuItem = (usersGroups, modules, roles, permissions, applicationId) => item => {
    if (!item.perm) return true;

    const modulePermissions = getModulePermissions(usersGroups, item.module, modules, roles, permissions, applicationId);
    return hasPerm(item.perm, modulePermissions);
};
