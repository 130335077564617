import React from 'react';
import Main from './components/Main';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';
import { useCookies } from 'react-cookie';
import { AuthProvider } from '@snc/auth';
import { assureToken, isTokenValid } from '@snc/api';
import { ActionProgressReport } from '@snc/ui';
import { EmiConfigProvider, useEmiConfig } from '@snc/emi-config';
import { getUser } from '@snc/app-bar';
import { configLoad } from './redux/config/ActionCreators';
import { userLoggedIn } from './redux/administration/users/ActionCreators';

// CSS
import './App.scss';
import 'typeface-roboto';

// SNC CSS Files
import './theme-variables.scss';
import './css/snc-mui-tables.scss';
import './css/snc-styled-list.scss';
import './css/snc-mui-controls.scss';
import './css/snc-layout.scss';
import './css/snc-text.scss';
import './css/snc-utils.scss';

export default function App() {
    return (
        <EmiConfigProvider>
            <AuthProvider>
                <AppInner />
            </AuthProvider>
        </EmiConfigProvider>
    );
}

function AppInner() {
    const [cookies, , removeCookie] = useCookies(['token']);

    const dispatch = useDispatch();

    const config = useEmiConfig();

    const [isLoadingUser, setLoadingUser] = React.useState(true);
    const [isLoadingConfig, setLoadingConfig] = React.useState(true);

    React.useEffect(() => {
        const loadUser = async () => {
            const user = await getUser();

            dispatch(userLoggedIn(user));

            setLoadingUser(false);
        };

        loadUser();
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(configLoad(config));

        if (config && config.THEME) {
            const theme = config.THEME;

            const html = document.getElementsByTagName('html')[0];
            Object.keys(theme).forEach((property, i) => {
                if (property === 'name') {
                    return;
                }
                html.style.setProperty('--' + property, theme[property]);
            });
        }

        if (config?.LAUNCHER?.BG_IMG)
            document.body.style = `background-image: url("${process.env.PUBLIC_URL}/${config.LAUNCHER.BG_IMG}") !important;`;

        const token = cookies['token'];

        if (!isTokenValid(token)) window.location = config.LOGIN_URL;
        else {
            assureToken(
                () => token,
                () => {
                    removeCookie('token', { path: '/' });
                    const redirectUri = `${config.LOGIN_URL || process.env.REACT_APP_LOGIN_URL}?redirect=${window.location}`;
                    window.location.replace(redirectUri);
                }
            );
        }

        setLoadingConfig(false);
    }, [config, cookies, removeCookie, dispatch]);

    if (isLoadingConfig || isLoadingUser) return <ActionProgressReport />;
    else
        return (
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <SnackbarProvider
                        maxSnack={1}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <div className="App">
                            <Main />
                        </div>
                    </SnackbarProvider>
                </MuiPickersUtilsProvider>
            </BrowserRouter>
        );
}
