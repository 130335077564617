import React from 'react';
import {
    Card,
    IconButton,
    Divider,
    CardContent,
    CardHeader,
    Tooltip,
    makeStyles,
    Grid,
    Typography,
    Box,
    Button,
    useTheme,
} from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SaveIcon from '@material-ui/icons/Save';
import { i18n } from 'i18n';
import clsx from 'clsx';
import { Searcher } from 'components/generic/search/Searcher';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Autocomplete from 'components/generic/autocomplete/Autocomplete';
import UserInfoWrapper from './UserDetails';
import UsersList from './list/UsersList';

const activeOptions = [
    {
        value: true,
        label: i18n.actives,
    },
    {
        value: false,
        label: i18n.noActives,
    },
    {
        value: undefined,
        label: i18n.all,
    },
];

export default function Users(props) {
    const {
        users,
        handleSaveUser,
        selectedUser,
        setSelectedUser,
        isLoading,
        searchText,
        setSearchText,
        setPage,
        active,
        setActive,
        formikRef,
    } = props;

    const classes = useStyles();
    const theme = useTheme();

    const [isNewUser, setIsNewUser] = React.useState(false);

    const disabledClassname = clsx({
        [classes.disabled]: !(selectedUser || isNewUser),
    });

    const loadingClassname = clsx({
        [classes.loading]: isLoading,
    });

    return (
        <div className={`row no-gutters w-100 h-100 ${loadingClassname}`}>
            <Card className="w-100 h-100 d-flex flex-column">
                <CardHeader
                    classes={{ action: classes.cardHeaderContent }}
                    title={i18n.users}
                    action={
                        <Box display="flex">
                            <Tooltip title={i18n.addUser}>
                                <Button style={{ border: '1px solid' }} onClick={() => setIsNewUser(true)}>
                                    <PersonAddIcon style={{ marginRight: theme.spacing(1) }} /> {i18n.newUser}
                                </Button>
                            </Tooltip>
                            <Tooltip title={i18n.save}>
                                <Button
                                    style={{ border: '1px solid', marginLeft: theme.spacing(1) }}
                                    onClick={() => {
                                        if (formikRef.current) formikRef.current.handleSubmit();
                                    }}
                                >
                                    <SaveIcon style={{ marginRight: theme.spacing(1) }} /> {i18n.save}
                                </Button>
                            </Tooltip>
                        </Box>
                    }
                />
                <Divider />
                <CardContent className={`h-100 w-100 row no-gutters flex-grow-1 overflow-auto pb-1`}>
                    <div className="col-3 pl-2 pr-4 h-100 d-flex flex-column" style={{ borderRight: '1px solid lightgrey' }}>
                        <Grid item container xs={12} style={{ flexBasis: 'auto' }}>
                            <Grid item xs={6} style={{ paddingRight: theme.spacing(2) }}>
                                <Searcher
                                    value={searchText}
                                    onChange={value => {
                                        setSearchText(value);
                                        setPage(0);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    margin={'none'}
                                    className={classes.autocomplete}
                                    key={'value'}
                                    getOptionLabel={item => item.label}
                                    value={activeOptions.find(item => item.value === active)}
                                    getOptionSelected={option => option.value === active}
                                    options={activeOptions}
                                    onChange={newValue => {
                                        if (!newValue) return;

                                        setActive(newValue.value);
                                        setPage(0);
                                    }}
                                    label={i18n.status}
                                />
                            </Grid>
                        </Grid>
                        <div className="w-100 mt-2" style={{ flexGrow: 1, overflowY: 'auto', overflowX: 'hidden' }}>
                            <UsersList
                                selectedUser={selectedUser}
                                setSelectedUser={user => {
                                    setSelectedUser(user);
                                    setIsNewUser(false);
                                }}
                                users={users?.results ?? []}
                                isLoading={isLoading}
                            />
                        </div>
                        <Grid item container justifyContent={'space-between'} display={'flex'} alignItems={'center'}>
                            <Grid item display={'flex'} alignItems={'center'}>
                                <Typography>
                                    {!!users && !!users.results
                                        ? `${users.page * users.size + 1} - ${users.page * users.size + users.results.length} de ${
                                              users.rownum
                                          }`
                                        : ''}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    disabled={users.page <= 0}
                                    onClick={() => {
                                        if (!!users && users.page > 0) {
                                            setPage(prev => prev - 1);
                                        }
                                    }}
                                >
                                    <NavigateBeforeIcon />
                                </IconButton>
                                <IconButton
                                    disabled={users.page >= users.pageCount}
                                    onClick={() => {
                                        if (!!users && users.page * users.size + users.results.length < users.rownum) {
                                            setPage(prev => prev + 1);
                                        }
                                    }}
                                >
                                    <NavigateNextIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={`col-9 w-100 h-100 p-3 d-flex flex-column ${disabledClassname}`}>
                        <UserInfoWrapper
                            newUser={isNewUser}
                            selectedUser={selectedUser}
                            handleSaveUser={handleSaveUser}
                            formikRef={formikRef}
                        />
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
const useStyles = makeStyles(() => ({
    disabled: {
        pointerEvents: 'none',
        opacity: 0.4,
    },
    loading: {
        pointerEvents: 'none',
        opacity: 0.7,
    },
    cardHeaderContent: {
        margin: 0,
    },
    autocomplete: {
        margin: 0,
    },
}));
