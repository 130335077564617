import React, { useState, useEffect } from 'react';
import { Searcher } from 'components/generic/search/Searcher';
import { Grid, Card, CardHeader, Divider, CardContent, Button, useTheme, makeStyles } from '@material-ui/core';
import { i18n } from 'i18n';
import SaveIcon from '@material-ui/icons/Save';
import RolesListAllGroups from './list/RolesListAllGroups';
import AssignmentUsersCard from './AssignmentUsersCard';
import { useAssignment } from './AssignmentWrapper';

export default function AssignmentAllGroups() {
    const { users, selectedUser, roles, update, groups } = useAssignment();

    const [roleSearchText, setRoleSearchText] = useState('');
    const [usersGroups, setUsersGroups] = useState([]);
    const [assignedRoles, setAssignedRoles] = useState([]);

    const theme = useTheme();
    const classes = useStyles();

    useEffect(() => {
        if (selectedUser) {
            const groupsFromUser = [],
                userRoles = [];
            selectedUser.groups.forEach(group => {
                const grp = groups.find(g => g.usersGroupId === group.usersGroupId);
                if (grp) groupsFromUser.push({ ...grp, ...group });
                group.roles.forEach(role => {
                    if (role.userId === selectedUser.userId) {
                        userRoles.push(role);
                    }
                });
            });

            setAssignedRoles(userRoles);
            setUsersGroups(groupsFromUser);
        }
    }, [groups, selectedUser]);

    function checkRoles(checkedRole, isChecked) {
        const actualRoles = [...assignedRoles];

        usersGroups.forEach(group => {
            const index = actualRoles.findIndex(role => role.roleId === checkedRole.roleId && role.usersGroupId === group.usersGroupId);
            const role = actualRoles[index];

            if (!!role && !isChecked) {
                actualRoles.splice(index, 1);
            } else if (isChecked && !!!role) {
                actualRoles.push({
                    granted: true,
                    roleId: checkedRole.roleId,
                    userId: selectedUser.userId,
                    usersGroupId: group.usersGroupId,
                });
            }
        });

        setAssignedRoles(actualRoles);
    }

    return (
        <div className="row no-gutters w-100 h-100 overflow-auto">
            {users ? (
                <Grid xs={12} md={6} style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(2), height: '100%' }}>
                    <AssignmentUsersCard />
                </Grid>
            ) : null}
            {roles ? (
                <div className={`col-12 col-md-6 pl-2 pr-2 h-100 `}>
                    <Card className={`w-100 h-100 d-flex flex-column ${selectedUser && !!usersGroups.length ? '' : classes.disabled}`}>
                        <CardHeader
                            classes={{ action: classes.cardHeaderContent }}
                            action={
                                <Button
                                    style={{ border: '1px solid' }}
                                    onClick={() => {
                                        const groups = [];
                                        selectedUser.groups.forEach(group => {
                                            const groupRoles = assignedRoles.filter(role => role.usersGroupId === group.usersGroupId);
                                            const updatedGroup = { ...group, roles: groupRoles };
                                            groups.push(updatedGroup);
                                        });
                                        const updatedUser = {
                                            ...selectedUser,
                                            groups: groups,
                                        };
                                        update(updatedUser);
                                    }}
                                >
                                    <SaveIcon style={{ marginRight: theme.spacing(1) }} /> {i18n.save}
                                </Button>
                            }
                            title={i18n.roles}
                        />
                        <Divider />
                        <CardContent className="h-100 w-100 flex-grow-1 overflow-auto d-flex flex-column flex-nowrap pb-1">
                            <Searcher value={roleSearchText} onChange={setRoleSearchText} />
                            <div className="w-100 flex-grow-1" style={{ overflowY: 'auto' }}>
                                <RolesListAllGroups
                                    roles={
                                        roleSearchText && roleSearchText !== ''
                                            ? roles.filter(
                                                  r => r.roleName.toLowerCase().indexOf(roleSearchText.toLowerCase()) >= 0 && r.active
                                              )
                                            : roles.filter(r => r.active)
                                    }
                                    assignedRoles={assignedRoles}
                                    setChecked={checkRoles}
                                    groups={usersGroups}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </div>
            ) : null}
        </div>
    );
}

const useStyles = makeStyles(() => ({
    cardHeaderContent: {
        margin: 0,
    },
    disabled: {
        pointerEvents: 'none',
        opacity: 0.7,
    },
}));
