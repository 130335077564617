import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionProgressReport } from '@snc/ui';
import axios from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { i18n } from 'i18n';
import * as authTypes from 'model/authTypes';
import { userLoggedIn } from 'redux/administration/users/ActionCreators';
import Users from './Users';

export default function UsersWrapper() {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const { config } = useSelector(state => state.config);
    const { loggedUser } = useSelector(state => state.users);

    const [selectedUser, setSelectedUser] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [size] = useState(50);
    const [active, setActive] = useState(true);

    const formikRef = useRef(null);

    const filter = React.useMemo(
        () => ({
            content: {
                active,
                ...(!!searchText?.length && { searchText }),
            },
            page,
            size,
        }),
        [active, page, searchText, size]
    );

    const {
        isLoading: isLoadingUsers,
        isSuccess: isSuccessUsers,
        data: searchedUsers,
    } = useQuery(['usersSearchAll', filter], () => axios.post(`${config.AUTH.BASE}/users/search-all`, filter));

    const { mutate: update, isLoading: isLoadingUpdate } = useMutation(
        user => {
            let updatedUser;
            if (user.authType) {
                switch (user.authType) {
                    case authTypes.LOCAL:
                        if (!!user?.newPassword && !!user.newPassword?.length) {
                            updatedUser = {
                                ...user,
                                authenticationMethods: [
                                    ...user.authenticationMethods.filter(am => am.type === authTypes.CODE || am.type === authTypes.RFID),
                                    {
                                        type: authTypes.LOCAL,
                                        password: user.newPassword,
                                    },
                                ],
                            };
                        } else {
                            updatedUser = { ...user };
                        }
                        break;
                    case authTypes.LDAP:
                        updatedUser = {
                            ...user,
                            authenticationMethods: [
                                ...user.authenticationMethods.filter(am => am.type === authTypes.CODE || am.type === authTypes.RFID),
                                {
                                    type: authTypes.LDAP,
                                    ldapUsername: user.dn,
                                },
                            ],
                        };
                        break;
                    default:
                        updatedUser = {
                            ...user,
                            authenticationMethods: [
                                ...user.authenticationMethods.filter(am => am.type === authTypes.CODE || am.type === authTypes.RFID),
                            ],
                        };
                }
            } else {
                updatedUser = { ...user };
            }

            return axios.put(`${config.AUTH.BASE}/users/${user.userId}`, updatedUser);
        },
        {
            onSuccess: response => {
                enqueueSnackbar(i18n.updated, { variant: 'success' });
                setSelectedUser(response.data);
                queryClient.invalidateQueries('usersSearchAll');
                // if the user updated is the loggedUser then update too the redux with userLoggedIn
                if (loggedUser && loggedUser.userId === response.data.userId) {
                    dispatch(userLoggedIn(response.data));
                }
            },
            onError: () => {
                enqueueSnackbar(i18n.error, { variant: 'error' });
            },
        }
    );

    const { mutate: create, isLoading: isLoadingCreate } = useMutation(
        user => {
            let newUser = {
                username: user.username,
                groups: [],
                name: user.name,
                firstSurname: user.firstSurname,
                secondSurname: user.secondSurname,
                allowedLogin: user.allowedLogin,
                phoneNumber: user.phoneNumber,
                mobilePhoneNumber: user.mobilePhoneNumber,
                email: user.email,
                image: user.image,
                imageType: user.imageType,
            };
            switch (user.authType) {
                case authTypes.LOCAL:
                    newUser = {
                        ...newUser,
                        authenticationMethods: [
                            {
                                type: authTypes.LOCAL,
                                password: user.newPassword,
                            },
                        ],
                    };
                    break;
                case authTypes.LDAP:
                    newUser = {
                        ...newUser,
                        authenticationMethods: [
                            {
                                type: authTypes.LDAP,
                                ldapUsername: user.dn,
                            },
                        ],
                    };
                    break;
                default:
                    newUser = {
                        ...newUser,
                        authenticationMethods: [],
                    };
            }

            newUser = {
                ...newUser,
                authenticationMethods: [
                    ...newUser.authenticationMethods,
                    {
                        type: authTypes.CODE,
                    },
                ],
            };
            return axios.post(`${config.AUTH.BASE}/users`, newUser);
        },
        {
            onSuccess: () => {
                enqueueSnackbar(i18n.created, { variant: 'success' });
                queryClient.invalidateQueries('usersSearchAll');
            },
            onError: () => {
                enqueueSnackbar(i18n.error, { variant: 'error' });
            },
        }
    );

    function handleSaveUser(userInfo, newUser) {
        if (newUser) {
            create(userInfo);
        } else {
            update(userInfo);
            setSelectedUser(null);
        }
    }

    const isLoading = isLoadingUsers;

    return (
        <div className="row no-gutters w-100 h-100 position-relative">
            {(isLoadingUpdate || isLoadingCreate || isLoading) && <ActionProgressReport />}
            <Users
                users={isSuccessUsers ? searchedUsers.data : []}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
                handleSaveUser={handleSaveUser}
                isLoading={isLoading}
                searchText={searchText}
                setSearchText={setSearchText}
                setPage={setPage}
                active={active}
                setActive={setActive}
                formikRef={formikRef}
            />
        </div>
    );
}
