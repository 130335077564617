import React, { useState, useEffect } from 'react';
import BasicCard from 'components/generic/card/basic-card.component';
import GroupsList from '../groups/list/GroupsList';
import RolesListPerGroup from './list/RolesListPerGroup';
import { Searcher } from 'components/generic/search/Searcher';
import SaveIcon from '@material-ui/icons/Save';
import { Grid, Card, CardHeader, Divider, CardContent, Button, makeStyles, useTheme } from '@material-ui/core';
import { i18n } from 'i18n';
import AssignmentUsersCard from './AssignmentUsersCard';
import { useAssignment } from './AssignmentWrapper';

export default function AssignmentPerGroup() {
    const { users, selectedUser, selectedGroup, setSelectedGroup, roles, update, groups, isLoadingGroups } = useAssignment();

    const classes = useStyles();
    const theme = useTheme();

    const [groupsSearchText, setGroupsSearchText] = useState('');
    const [roleSearchText, setRoleSearchText] = useState('');
    const [usersGroups, setUsersGroups] = useState([]);
    const [assignedRoles, setAssignedRoles] = useState([]);

    useEffect(() => {
        if (selectedUser) {
            const groupsFromUser = [],
                userRoles = [];
            for (const group of selectedUser.groups) {
                const grp = groups.find(g => g.usersGroupId === group.usersGroupId);
                if (grp) groupsFromUser.push({ ...grp, ...group });
                for (const role of group.roles) {
                    if (role.userId === selectedUser.userId) {
                        userRoles.push(role);
                    }
                }
            }
            setAssignedRoles(userRoles);
            setUsersGroups(groupsFromUser);
        }
    }, [selectedUser, groups]);

    function checkRoles(checkedRole) {
        const actualRoles = [...assignedRoles];
        let isRoleFound = false;
        let counter = 0;
        while (!isRoleFound && counter < actualRoles.length) {
            const role = actualRoles[counter];
            if (role.roleId === checkedRole.roleId && role.usersGroupId === selectedGroup.usersGroupId) {
                isRoleFound = true;
            }
            counter++;
        }
        if (isRoleFound) {
            actualRoles.splice(counter - 1, 1);
        } else {
            actualRoles.push({
                granted: true,
                roleId: checkedRole.roleId,
                userId: selectedUser.userId,
                usersGroupId: selectedGroup.usersGroupId,
                valid: true,
            });
        }
        setAssignedRoles(actualRoles);
    }
    return (
        <div className="row no-gutters w-100 h-100 overflow-auto">
            {users ? (
                <Grid xs={12} md={4} style={{ paddingRight: theme.spacing(2), height: '100%' }}>
                    <AssignmentUsersCard />
                </Grid>
            ) : null}
            {selectedUser && selectedUser.groups ? (
                <Grid item xs={12} md={4} style={{ paddingRight: theme.spacing(2), height: '100%' }}>
                    <BasicCard title="Grupos">
                        <Searcher value={groupsSearchText} onChange={setGroupsSearchText} />
                        <div className="w-100 flex-grow-1" style={{ overflowY: 'auto', marginTop: theme.spacing(1) }}>
                            <GroupsList
                                groups={
                                    groupsSearchText && groupsSearchText !== ''
                                        ? usersGroups.filter(
                                              g => g.usersGroupName.toLowerCase().indexOf(groupsSearchText.toLowerCase()) >= 0
                                          )
                                        : usersGroups
                                }
                                selectedGroup={selectedGroup}
                                setSelectedGroup={setSelectedGroup}
                                isLoading={isLoadingGroups}
                            />
                        </div>
                    </BasicCard>
                </Grid>
            ) : null}
            {selectedGroup && roles ? (
                <Grid item xs={12} md={4} style={{ height: '100%' }}>
                    <Card className="w-100 h-100 d-flex flex-column">
                        <CardHeader
                            title={i18n.roles}
                            classes={{ action: classes.cardHeaderContent }}
                            action={
                                <Button
                                    style={{ border: '1px solid' }}
                                    onClick={() => {
                                        const groups = [];
                                        selectedUser.groups.forEach(group => {
                                            const groupRoles = assignedRoles.filter(role => role.usersGroupId === group.usersGroupId);
                                            const updatedGroup = { ...group, roles: groupRoles };
                                            groups.push(updatedGroup);
                                        });
                                        const updatedUser = {
                                            ...selectedUser,
                                            groups: groups,
                                        };
                                        update(updatedUser);
                                    }}
                                >
                                    <SaveIcon style={{ marginRight: theme.spacing(1) }} /> {i18n.save}
                                </Button>
                            }
                        />
                        <Divider />
                        <CardContent className="w-100 flex-grow-1 d-flex flex-column" style={{ overflowY: 'auto' }}>
                            <Searcher value={roleSearchText} onChange={setRoleSearchText} />
                            <div className="w-100 flex-grow-1" style={{ overflowY: 'auto', marginTop: theme.spacing(1) }}>
                                <RolesListPerGroup
                                    roles={
                                        roleSearchText && roleSearchText !== ''
                                            ? roles.filter(
                                                  r => r.roleName.toLowerCase().indexOf(roleSearchText.toLowerCase()) >= 0 && r.active
                                              )
                                            : roles.filter(r => r.active)
                                    }
                                    checked={assignedRoles}
                                    setChecked={checkRoles}
                                    selectedGroup={selectedGroup}
                                />
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            ) : null}
        </div>
    );
}

const useStyles = makeStyles(() => ({
    cardHeaderContent: {
        margin: 0,
    },
}));
