import React from 'react';
import { TextField } from '@material-ui/core';
import ModulesList from '../list/ModulesList';
import PermissionsList from '../list/PermissionsList';
import { useSelector } from 'react-redux';
import { i18n } from 'i18n';
import { Autocomplete } from '@material-ui/lab';

export default function RolesManagement(props) {
    const { values, setFieldValue, selectedAppId, setSelectedAppId, selectedModule, setSelectedModule, modules } = props;

    const { apps } = useSelector(state => state.apps);

    const handleSetChecked = check => {
        const currentPermissions = values.permissions;

        const found = currentPermissions.find(cp => cp.permissionId === check.permissionId);

        if (found) {
            setFieldValue(
                'permissions',
                currentPermissions.filter(c => c.permissionId !== check.permissionId)
            );
        } else {
            setFieldValue('permissions', [...currentPermissions, check]);
        }
    };

    const selectedApp = React.useMemo(() => apps.find(app => app.applicationId === selectedAppId), [apps, selectedAppId]);

    return (
        <div
            className="row no-gutters p-1 d-flex flex-column flex-grow-1 flex-nowrap"
            style={{ overflowY: 'auto', flexWrap: 'nowrap !important' }}
        >
            <div className="row no-gutters w-100">
                <Autocomplete
                    fullWidth
                    options={apps ?? []}
                    value={selectedApp}
                    getOptionLabel={app => app.displayName}
                    onChange={(_, value) => {
                        if (!value?.applicationId) {
                            setSelectedAppId(apps && apps.length > 0 ? apps[0].applicationId : -1);
                            setSelectedModule(null);
                        } else {
                            setSelectedAppId(value?.applicationId);
                            setSelectedModule(null);
                        }
                    }}
                    disableClearable={true}
                    noOptionsText={i18n.noElementsAvailable}
                    renderInput={params => <TextField {...params} fullWidth label={i18n.app} placeholder={i18n.app} variant="outlined" />}
                />
            </div>
            <div className="row no-gutters w-100 flex-grow-1" style={{ overflowY: 'auto' }}>
                <div className="col-12 col-md-6 h-100 d-flex flex-column">
                    <ModulesList modules={modules ?? []} selectedModule={selectedModule} setSelectedModule={setSelectedModule} />
                </div>
                {selectedModule ? (
                    <div className="col-12 col-md-6 h-100 d-flex flex-column">
                        <PermissionsList
                            permissions={selectedModule.permissions}
                            checked={values.permissions}
                            setChecked={handleSetChecked}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
}
