import React from 'react';
import UserAuthForm from './UserAuthForm';
import { Divider } from '@material-ui/core';
import { Form } from 'formik';
import * as authTypes from 'model/authTypes';
import UserInfoForm from './UserInfoForm';

const UserDetailsForm = props => {
    const { setFieldValue, selectedUser, newUser, resetForm, setValues } = props;

    React.useEffect(() => {
        if (selectedUser && !newUser) {
            const { authenticationMethods } = selectedUser;

            let value = {
                username: selectedUser.username ?? '',
                active: selectedUser.active ?? true,
                name: selectedUser.name ?? '',
                firstSurname: selectedUser.firstSurname ?? '',
                secondSurname: selectedUser.secondSurname ?? '',
                phoneNumber: selectedUser.phoneNumber ?? '',
                mobilePhoneNumber: selectedUser.mobilePhoneNumber ?? '',
                email: selectedUser.email ?? '',
                image: selectedUser.image ?? '',
                imageType: selectedUser.imageType ?? '',
                externalCode: selectedUser.externalCode ?? '',
                allowedLogin: selectedUser.active ? selectedUser.allowedLogin ?? false : false,
            };

            if (authenticationMethods !== null && authenticationMethods.length > 0) {
                let auth = authTypes.NONE;
                if (authenticationMethods.find(am => am.type === authTypes.LDAP)) auth = authTypes.LDAP;
                if (authenticationMethods.find(am => am.type === authTypes.LOCAL)) auth = authTypes.LOCAL;

                value = { ...value, authType: auth };
            }

            setValues({
                ...value,
                dn:
                    selectedUser.authenticationMethods !== null && selectedUser.authenticationMethods.length > 0
                        ? selectedUser.authenticationMethods.find(authMeth => authMeth.type === authTypes.LDAP)?.ldapUsername || ''
                        : '',
            });
        }

        if (newUser) {
            resetForm();
        }
    }, [selectedUser, newUser, setFieldValue, setValues, resetForm]);

    return (
        <Form className="w-100 h-100 d-flex flex-column" style={{ overflowY: 'auto' }} noValidate>
            <UserAuthForm {...props} />
            <div className="mb-3" />
            <Divider />
            <div className="mb-3" />
            <div className="flex-grow-1">
                <UserInfoForm {...props} />
            </div>
        </Form>
    );
};

export default UserDetailsForm;
