import React from 'react';
import Roles from './Roles';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ActionProgressReport } from '@snc/ui';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { i18n } from 'i18n';

const RolesWrapper = () => {
    const { config } = useSelector(state => state.config);

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const { apps } = useSelector(state => state.apps);

    const [selectedRole, setSelectedRole] = React.useState(null);
    const [selectedAppId, setSelectedAppId] = React.useState(apps && apps.length > 0 ? apps[0].applicationId : -1);
    const [selectedModule, setSelectedModule] = React.useState(null);
    const [searchText, setSearchText] = React.useState('');
    const [active, setActive] = React.useState(true);
    const [page, setPage] = React.useState(0);
    const [size] = React.useState(50);

    const formikRef = React.useRef(null);

    const filter = React.useMemo(
        () => ({
            content: {
                active,
                ...(!!searchText?.length && { searchText }),
            },
            page,
            size,
        }),
        [active, page, searchText, size]
    );

    const {
        isLoading: isLoadingRoles,
        isSuccess,
        data: roles,
    } = useQuery(['rolesSearchAll', filter], () => axios.post(`${config.AUTH.BASE}/roles/search-all`, filter));

    const { mutate: update, isLoading: isLoadingUpdate } = useMutation(
        updatedRole => {
            return axios.put(`${config.AUTH.BASE}/roles/${updatedRole.roleId}`, updatedRole);
        },
        {
            onSuccess: ({ data: updatedRole }) => {
                enqueueSnackbar(i18n.updated, { variant: 'success' });
                queryClient.invalidateQueries('getUsers');
                queryClient.invalidateQueries('groupsSearchAll');
                queryClient.invalidateQueries('rolesSearchAll');
                setSelectedRole(updatedRole);
            },
            onError: () => {
                enqueueSnackbar(i18n.error, { variant: 'error' });
            },
        }
    );

    const { mutate: create, isLoadingCreate } = useMutation(
        newRole => {
            return axios.post(`${config.AUTH.BASE}/roles`, newRole);
        },
        {
            onSuccess: ({ data: newRole }) => {
                enqueueSnackbar(i18n.created, { variant: 'success' });
                queryClient.invalidateQueries('getUsers');
                queryClient.invalidateQueries('groupsSearchAll');
                queryClient.invalidateQueries('rolesSearchAll');
                setSelectedRole(newRole);
            },
            onError: () => {
                enqueueSnackbar(i18n.error, { variant: 'error' });
            },
        }
    );

    const onChangeSelectedRole = role => {
        setSelectedRole(role);
    };

    const isLoading = isLoadingRoles;

    return (
        <div className="row no-gutters w-100 h-100 position-relative">
            {(isLoadingCreate || isLoadingUpdate || isLoading) && <ActionProgressReport />}
            <Roles
                roles={isSuccess ? roles.data : []}
                selectedRole={selectedRole}
                isLoading={isLoading}
                setSelectedRole={onChangeSelectedRole}
                update={update}
                create={create}
                active={active}
                setActive={setActive}
                searchText={searchText}
                setSearchText={setSearchText}
                setPage={setPage}
                formikRef={formikRef}
                selectedAppId={selectedAppId}
                setSelectedAppId={setSelectedAppId}
                selectedModule={selectedModule}
                setSelectedModule={setSelectedModule}
            />
        </div>
    );
};

export default RolesWrapper;
