import React from 'react';
import { Typography, Avatar, makeStyles, Grid } from '@material-ui/core';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import SncFileUpload from 'components/generic/file-upload/snc-file-upload.component';
import { CheckboxWithLabel } from 'formik-material-ui';
import { i18n } from 'i18n';
import { imageToB64 } from 'utils/imageUtils';

const GroupsInfoForm = props => {
    const classes = useStyles();

    return (
        <Grid container style={{ height: '100%', width: '100%', margin: 0 }}>
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                    {i18n.groupData}
                </Typography>
            </Grid>
            {props.values.image ? (
                <Grid xs={12} md={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Avatar
                        className="img-responsive fit-image"
                        src={`data:${props.values.imageType};base64,${props.values.image}`}
                        style={{ height: 120, width: 120 }}
                    />
                </Grid>
            ) : null}
            <Grid xs={12} md={props.values.image ? 2 : 3} style={{ padding: '8px' }}>
                <SncFileUpload
                    className={classes.upload}
                    imagePreviewMaxHeight={100}
                    acceptedFileTypes={['image/*']}
                    maxFileSize={'1MB'}
                    allowMultiple={false}
                    instantUpload
                    server={{
                        process: (fieldName, file, metadata, load) => {
                            imageToB64(file).then(b64 => {
                                props.setFieldValue('image', b64.substr(b64.indexOf(',') + 1));
                                props.setFieldValue('imageType', b64.substring(b64.indexOf(':') + 1, b64.indexOf(';')));
                                load(b64);
                            });
                        },
                        load: (source, load) => {
                            fetch(source)
                                .then(res => {
                                    return res.blob();
                                })
                                .then(blob => {
                                    return load(blob);
                                });
                        },
                    }}
                />
            </Grid>
            <Grid xs={12} sm={3} style={{ padding: '8px', alignSelf: 'center' }}>
                <Field name="usersGroupName" required type="text" variant="outlined" fullWidth label={i18n.name} component={TextField} />
            </Grid>
            <Grid xs={12} sm={3} style={{ padding: '8px', alignSelf: 'center' }}>
                <Field
                    name="description"
                    required
                    type="text"
                    variant="outlined"
                    fullWidth
                    label={i18n.description}
                    component={TextField}
                />
            </Grid>
            <Grid item xs={12} sm={2} className={classes.label}>
                <Field name="active" component={CheckboxWithLabel} Label={{ label: i18n.active }} type="checkbox" color="primary" />
            </Grid>
        </Grid>
    );
};

const useStyles = makeStyles({
    upload: {
        '& .filepond--credits': {
            display: 'none',
        },
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiFormControlLabel-root': {
            margin: 0,
        },
    },
});

export default GroupsInfoForm;
