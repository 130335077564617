import React from 'react';
import { List, ListItem, ListItemText, ListSubheader, ListItemIcon, Checkbox } from '@material-ui/core';
import { i18n } from 'i18n';

const PermissionsList = props => {
    const { permissions, checked, setChecked } = props;

    return (
        <>
            <ListSubheader className="mt-3">{i18n.permissions}</ListSubheader>
            <div className="row no-gutters w-100 flex-grow-1" style={{ overflowY: 'auto' }}>
                <List className="w-100" style={{ overflowY: 'auto' }}>
                    {permissions.map(permission => (
                        <ListItem button dense key={permission.permissionId} onClick={() => setChecked(permission)}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={checked.find(p => p.permissionId === permission.permissionId) ? true : false}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary={permission.displayName} />
                        </ListItem>
                    ))}
                </List>
            </div>
        </>
    );
};

export default PermissionsList;
