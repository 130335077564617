import React from 'react';
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core';
import { i18n } from 'i18n';

const ModulesList = props => {
    const { modules, selectedModule, setSelectedModule } = props;
    return (
        <>
            <ListSubheader>{i18n.modules}</ListSubheader>
            <div className="row no-gutters w-100 flex-grow-1" style={{ overflowY: 'auto' }}>
                <List className="w-100 h-100" style={{ overflowY: 'auto' }}>
                    {modules.map(module => (
                        <ListItem
                            button
                            dense
                            key={module.moduleId}
                            onClick={() => setSelectedModule(module)}
                            selected={selectedModule && selectedModule.moduleId === module.moduleId}
                        >
                            <ListItemText primary={module.displayName} />
                        </ListItem>
                    ))}
                </List>
            </div>
        </>
    );
};

export default ModulesList;
