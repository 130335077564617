import React from 'react';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, makeStyles } from '@material-ui/core';
import GroupUsersCount from '../others/GroupsUsersCount';
import { SkeletonList } from 'components/generic/loader/List';

const GroupsList = props => {
    const { groups, setSelectedGroup, selectedGroup, showCount, isLoading } = props;
    const classes = useStyles();

    if (isLoading) return <SkeletonList numRows={8} />;

    return groups ? (
        <List className="w-100 h-100" style={{ overflowY: 'auto' }}>
            {groups.map(group => {
                return (
                    <ListItem
                        className={!group.active ? classes.disabled : null}
                        selected={selectedGroup && group.usersGroupId === selectedGroup.usersGroupId}
                        onClick={() => setSelectedGroup(group)}
                        key={group.usersGroupId}
                        dense
                        button
                    >
                        <ListItemAvatar>
                            {group.image && group.imageType ? (
                                <Avatar src={`data:${group.imageType};base64,${group.image}`} />
                            ) : (
                                <Avatar src={`${process.env.PUBLIC_URL}/assets/GROUP.svg`} />
                            )}
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <div className="row no-gutters align-items-center">
                                    <Typography className="mr-2"> {group.usersGroupName} </Typography>
                                    {showCount ? <GroupUsersCount count={group.users.length} /> : null}
                                </div>
                            }
                            secondary={group.description}
                        />
                    </ListItem>
                );
            })}
        </List>
    ) : null;
};

const useStyles = makeStyles(() => ({
    disabled: {
        opacity: 0.5,
    },
}));

export default GroupsList;
