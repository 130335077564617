import LocalizedStrings from 'react-localization';

export const i18n = new LocalizedStrings({
    es: {
        title: 'Launcher',
        menu: 'Menú',
        search: 'Buscar',

        appLoadError: 'No ha sido posible cargar la aplicación',
        contactAdmin: 'Contacte con su administrador',
        none: 'NINGUNA',
        cancel: 'Cancelar',
        accept: 'Aceptar',
        updated: 'Actualizado',
        created: 'Creado',
        error: 'Error',

        chooseAnOption: 'Seleccione una opción desde el menú',
        selectAll: 'Seleccionar todos',

        // Admin menu
        administration: 'Administración',
        access: 'Acceso',
        users: 'Usuarios',
        groups: 'Grupos',
        roles: 'Roles',
        permissions: 'Permisos',
        passwordRequired: 'Ingrese una contraseña o asigne usuario de LDAP',
        assignedUsers: 'Usuarios asignados',
        assignUsers: 'Asignar usuarios',

        administrationWelcome: 'Bienvenido al área de configuración',
        administrationSelectOption: 'Seleccione una opción desde el menú',

        //#region LINK
        links: 'Enlaces externos',
        name: 'Nombre',
        url: 'Url',
        position: 'Posición',
        description: 'Descripción',
        //#endregion

        //#region FORMIK
        requiredField: 'Campo Requerido',
        stringMax255: 'El valor debe contener 255 caracteres o menos',
        numberType: 'El valor debe ser númerico',
        integerType: 'El valor debe ser entero',
        positiveNumberType: 'El valor ha de ser un número positivo',
        stringType: 'El valor ha de ser un texto',
        dateType: 'El valor ha de ser una fecha',
        datetimeType: 'El valor ha de ser una fecha-hora',
        timeType: 'El valor ha de ser una hora',
        durationType: 'El valor debe contener un número de horas y minutos',
        dateRangeError: 'La Fecha Hasta debe ser posterior o igual a la Fecha Desde',
        newPasswordRequired: 'Nueva contraseña requerida',
        //#endregion FIN FORMIK

        //#region Forms
        active: 'Activo',
        allowedLogin: 'Permitir inicio de sesión',
        externalCode: 'Código externo',
        email: 'Correo',
        telephone: 'Teléfono',
        mobile: 'Móvil',
        firstSurname: 'Primer apellido',
        secondSurname: 'Segundo appelido',
        dragFile: 'Arrastre el archivo o',
        explore: 'Explore',
        username: 'Nombre de usuario',
        auth: 'Autenticación',
        setPassword: 'Establecer contraseña',
        linkLDAP: 'Vincular LDAP',
        //#endregion End Forms

        clear: 'Limpiar',
        create: 'Crear',
        edit: 'Editar',

        addLink: 'Añadir enlace',
        deleteLink: 'Borrar enlace',
        addExternal: 'Agregar enlace externo',
        editExternal: 'Editar enlace externo',

        save: 'Guardar',
        app: 'Aplicación',
        applications: 'Mis aplicaciones',
        external: 'Enlaces externos',
        modules: 'Modulos',

        assignment: 'Asignación',

        profile: 'Perfil',
        logout: 'Desconectar',

        unauthorized: 'No dispone de permisos para visualizar esta sección',
        personalData: 'Datos personales',
        myProfile: 'Mi perfil',
        changePassword: 'Cambiar contraseña',
        changePasswordSuccess: 'Tu contraseña ha sido cambiada correctamente',
        changePasswordError: 'Ha habido un error, inténtalo de nuevo',
        changePasswordErrorAuthType: 'No es posible cambiar la contraseña porque el tipo de autorizacion no es local.',
        oldPassword: 'Contraseña antigua',
        newPassword: 'Contraseña nueva',
        confirmNewPassword: 'Confirmar nueva contraseña',
        passwordMatch: 'Las contraseñas deben coincidir',

        status: 'Estado',
        addGroup: 'Agregar nuevo grupo',
        newGroup: 'Nuevo grupo',
        addUser: 'Agregar nuevo usuario',
        newUser: 'Nuevo usuario',
        actives: 'Activos',
        noActives: 'No activos',
        all: 'Todos',
        asignUser: 'Asignar usuario',
        unassignUser: 'Desasignar usuario',
        disableRole: 'Desactivar rol',
        addRole: 'Añadir sol',
        newRole: 'Nuevo rol',
        groupData: 'Datos del grupo',
        noUsersOutGroup: 'No hay usuarios en el sistema que no estén en este grupo',
        rolData: 'Datos del rol',
        authData: 'Datos de autenticación',
        noGroups: 'Sin grupos',

        confirmChangePasswordTitle: 'Confirmar cambio de contraseña',
        confirmChangePasswordMessage: '¿Está seguro de que desea cambiar la contraseña?',
        confirmChangePasswordMessageInfo: 'Se cerrará la sesión actual y se le pedirá que inicie sesión con la nueva contraseña.',
    },
    en: {
        title: 'Launcher',
        menu: 'Menu',
        search: 'Search',

        appLoadError: "Can't load the app",
        contactAdmin: 'Get in contact with your administrador',

        none: 'NONE',
        cancel: 'Cancel',
        accept: 'Accept',
        updated: 'Updated',
        created: 'Created',
        error: 'Error',

        chooseAnOption: 'Choose an option from the menu',
        selectAll: 'Select all',

        // Admin menu
        administration: 'Administration',
        access: 'Access',
        users: 'Users',
        groups: 'Groups',
        roles: 'Roles',
        permissions: 'Permissions',
        passwordRequired: 'Set a password or set the linked LDAP user',
        assignedUsers: 'Assigned users',
        assignUsers: 'Assign users',

        administrationWelcome: 'Welcome to administration area',
        administrationSelectOption: 'Select an option',

        //#region LINK
        links: 'External links',
        name: 'Name',
        url: 'Url',
        position: 'Posición',
        description: 'Descripción',
        //#endregion

        //#region FORMIK
        requiredField: 'Required Field',
        stringMax255: 'Value must have 255 or less characters',
        numberType: 'Value must be a number',
        integerType: 'Value must be an integer',
        positiveNumberType: 'Value must be a positive number',
        stringType: 'Value must be a text',
        dateType: 'Value must be a date',
        datetimeType: 'Value must be a datetime',
        timeType: 'Value must be a time',
        durationType: 'Value must contain an amount of hours and minutes',
        dateRangeError: 'Until Date must be bigger or equal to From Date',
        newPasswordRequired: 'New password required',
        ////#endregion FIN FORMIK

        //#region Forms
        active: 'Active',
        allowedLogin: 'Allow login',
        externalCode: 'External code',
        email: 'Email',
        telephone: 'Phone',
        mobile: 'Mobile',
        firstSurname: 'surname',
        secondSurname: 'second surname',
        dragFile: 'Drag a file or',
        explore: 'Explore',
        username: 'Username',
        auth: 'Authentication',
        setPassword: 'Set password',
        linkLDAP: 'Link LDAP',
        //#endregion End Forms

        clear: 'Clear',
        create: 'Create',
        edit: 'Edit',

        addLink: 'Add Link',
        deleteLink: 'Delete Link',
        addExternal: 'Add external link',
        editExternal: 'Edit extenal link',

        save: 'Save',
        app: 'Application',
        applications: 'My applications',
        external: 'External links',
        modules: 'Modules',

        assignment: 'Assignment',

        profile: 'Profile',
        logout: 'Logout',

        unauthorized: 'Not enought permissions to display this section',
        personalData: 'Personal data',
        myProfile: 'My profile',
        changePassword: 'Change password',
        changePasswordSuccess: 'Your password has been changed',
        changePasswordError: "There's been an error, try again",
        changePasswordErrorAuthType: 'Cannot change password because auth type is not local.',
        oldPassword: 'Old password',
        newPassword: 'New password',
        confirmNewPassword: 'Confirm new password',
        passwordMatch: 'Passwords must match',

        status: 'Status',
        addGroup: 'Add new group',
        newGroup: 'New group',
        addUser: 'Add new user',
        newUser: 'New user',
        actives: 'Actives',
        noActives: 'No actives',
        all: 'All',
        asignUser: 'Asign user',
        unassignUser: 'Unassign user',
        disableRole: 'Disable role',
        addRole: 'Add role',
        newRole: 'New role',
        groupData: 'Group data',
        noUsersOutGroup: 'There are not users outside this group',
        rolData: 'Rol data',
        authData: 'Auth data',
        noGroups: 'No groups',

        confirmChangePasswordTitle: 'Confirm password change',
        confirmChangePasswordMessage: 'Are you sure you want to change the password?',
        confirmChangePasswordMessageInfo: 'Current session will be closed and you will be asked to login with the new password.',
    },
});
