import { Card, CardContent, CardHeader, Divider, Grid, Tooltip, Button, Box, useTheme, makeStyles, Typography } from '@material-ui/core';
import { useCookie } from 'react-use';
import { Field, Form, Formik } from 'formik';
import { ActionProgressReport, Modal } from '@snc/ui';
import React from 'react';
import * as Yup from 'yup';
import * as authTypes from 'model/authTypes';
import InputPassword from 'components/generic/passwordChange/passwordInput.component';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { i18n } from 'i18n';
import { useSelector } from 'react-redux';
import { useMutation, useQueryClient } from 'react-query';
import { useEmiConfig } from '@snc/emi-config';

const ChangeMyPassword = () => {
    const { config } = useSelector(state => state.config);
    const { loggedUser } = useSelector(state => state.users);

    const theme = useTheme();
    const classes = useStyles();

    const { authenticationMethods } = loggedUser;

    const formikRef = React.useRef(null);

    let auth = authTypes.NONE;

    if (authenticationMethods !== null && authenticationMethods.length > 0) {
        if (authenticationMethods.find(am => am.type === authTypes.LDAP)) auth = authTypes.LDAP;
        if (authenticationMethods.find(am => am.type === authTypes.LOCAL)) auth = authTypes.LOCAL;
    }

    return (
        <Card className="w-100 h-100 d-flex flex-column">
            <CardHeader
                classes={{ action: classes.cardHeaderContent }}
                action={
                    <Box display="flex">
                        <Tooltip title={i18n.save}>
                            <Button
                                style={{ border: '1px solid' }}
                                onClick={() => {
                                    if (formikRef.current) formikRef.current.handleSubmit();
                                }}
                            >
                                <SaveIcon style={{ marginRight: theme.spacing(1) }} /> {i18n.save}
                            </Button>
                        </Tooltip>
                    </Box>
                }
                title={i18n.changePassword}
            />
            <Divider />
            <CardContent>
                {auth === authTypes.LOCAL ? (
                    <ChangeMyPasswordForm
                        changeMyPasswordURL={`${config.AUTH.BASE}/change_my_password`}
                        userId={loggedUser.userId}
                        formikRef={formikRef}
                    />
                ) : (
                    i18n.changePasswordErrorAuthType
                )}
            </CardContent>
        </Card>
    );
};

const ChangeMyPasswordForm = ({ changeMyPasswordURL, userId, formikRef }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [, , deleteCookie] = useCookie('token');

    const config = useEmiConfig();

    const [open, setOpen] = React.useState(false);
    const queryClient = useQueryClient();

    const initialValues = {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    };

    const validationSchema = Yup.object({
        oldPassword: Yup.string().required(i18n.requiredField),
        newPassword: Yup.string().required(i18n.requiredField),
        confirmNewPassword: Yup.string()
            .required(i18n.requiredField)
            .oneOf([Yup.ref('newPassword'), null], i18n.passwordMatch),
    });

    const { mutate: changePassword, isLoading } = useMutation(
        values =>
            axios.post(changeMyPasswordURL, {
                oldPassword: values.oldPassword,
                newPassword: values.newPassword,
                userId,
            }),
        {
            onSuccess: async response => {
                enqueueSnackbar(i18n.changePasswordSuccess, { variant: 'success' });
                queryClient.invalidateQueries('usersSearchAll');

                try {
                    await axios.post(`${config.AUTH.BASE}/refresh-token/revoke`);
                } catch (e) {
                    console.error(e);
                }
                deleteCookie();
                window.location.replace(`${config.LOGIN_URL}?redirect=${window.location}`);
                setOpen(false);
            },
            onError: () => enqueueSnackbar(i18n.changePasswordError, { variant: 'error' }),
        }
    );

    const onAccept = () => {
        if (formikRef.current) changePassword(formikRef.current.values);
    };

    const onClose = () => {
        if (formikRef.current) formikRef.current.setSubmitting(false);
        setOpen(false);
    };

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={() => {
                    setOpen(true);
                }}
                validationSchema={validationSchema}
            >
                <Form>
                    {isLoading && <ActionProgressReport />}
                    <ChangeMyPasswordFormLayout />
                </Form>
            </Formik>
            {open && <ChangePasswordConfirmationModal open={open} setOpen={setOpen} onAccept={onAccept} onClose={onClose} />}
        </>
    );
};

const ChangeMyPasswordFormLayout = () => {
    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <Field
                    variant="outlined"
                    fullWidth
                    label={i18n.oldPassword}
                    name="oldPassword"
                    autoComplete="false"
                    required
                    component={InputPassword}
                />
            </Grid>
            <Grid item>
                <Field
                    variant="outlined"
                    fullWidth
                    label={i18n.newPassword}
                    name="newPassword"
                    autoComplete="false"
                    required
                    component={InputPassword}
                />
            </Grid>
            <Grid item>
                <Field
                    variant="outlined"
                    fullWidth
                    label={i18n.confirmNewPassword}
                    name="confirmNewPassword"
                    autoComplete="false"
                    required
                    component={InputPassword}
                />
            </Grid>
        </Grid>
    );
};

export function ChangePasswordConfirmationModal(props) {
    const { open, setOpen, onAccept, onClose } = props;

    const classes = useStyles();
    const theme = useTheme();

    return (
        <Modal
            title={i18n.confirmChangePasswordTitle}
            open={!!open}
            setOpen={setOpen}
            maxWidth={'sm'}
            className={classes.modal}
            disablePrimaryAction={true}
            disableSecondaryAction={true}
            closable={true}
            onClose={onClose}
        >
            <Box className={classes.modalContainer} width={1} style={{ backgroundColor: '#FFFFFF' }}>
                <Box width={1} height={1} display="flex" padding={2} flexDirection="column">
                    <Box width={1} paddingBottom={3} display="flex" flexDirection="column">
                        <Typography variant="subtitle1" className={classes.text}>
                            {i18n.confirmChangePasswordMessage}
                        </Typography>
                        <Typography variant="subtitle2" className={classes.text} style={{ paddingTop: theme.spacing(2) }}>
                            {i18n.confirmChangePasswordMessageInfo}
                        </Typography>
                    </Box>

                    <Box width={1} display="flex">
                        <Button className={classes.button} onClick={() => onAccept()} variant="contained">
                            {i18n.accept}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

const useStyles = makeStyles(theme => ({
    cardHeaderContent: {
        margin: 0,
    },
    modalContainer: {
        backgroundColor: theme.palette.background.default,
        height: '100%',
        width: '100%',
    },
    modal: {
        height: '100%',
        '& [class*="MuiDialogContent-dividers"]': {
            padding: '0px !important',
        },
        '& [class*="MuiDialogActions-root"]': {
            display: 'none',
        },
        '& [class*="MuiDialogContent-root"]': {
            [theme.breakpoints.down('xs')]: {
                backgroundColor: theme.palette.background.default,
            },
        },
        '& [class*="MuiDialog-paper"]': {
            [theme.breakpoints.down('xs')]: {
                margin: 0,
            },
        },
        '& [class*="MuiDialog-paperFullWidth"]': {
            [theme.breakpoints.down('xs')]: {
                width: '95%',
            },
        },
        '& ::-webkit-scrollbar': {
            [theme.breakpoints.down('xs')]: {
                width: '0px !important',
            },
        },
    },
    button: {
        height: '48px',
        width: '100%',
        backgroundColor: '#333333',
        color: '#FFFFFF',
        borderRadius: '0.4rem',
        fontWeight: 550,
        boxShadow: '0 4px 10px -10px #000 !important',
        '&:hover': {
            backgroundColor: '#333333',
            color: '#FFFFFF',
        },
    },
    text: {
        textAlign: 'center',
        width: '100%',
    },
    infoIcon: {
        color: '#000',
        height: 20,
        width: 20,
    },
}));

export default ChangeMyPassword;
