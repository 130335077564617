import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Checkbox } from '@material-ui/core';
import { useAssignment } from '../AssignmentWrapper';
import { SkeletonList } from 'components/generic/loader/List';

export default function RolesListPerGroup(props) {
    const { roles, checked, setChecked, selectedGroup } = props;

    const { isLoadingRoles } = useAssignment();

    if (isLoadingRoles) return <SkeletonList numRows={8} rowHeight={40} />;

    return (
        <List className="w-100 h-100" style={{ overflowY: 'auto' }}>
            {roles.map(role => (
                <ListItem button dense key={role.roleId} onClick={() => setChecked(role)}>
                    <ListItemIcon>
                        <Checkbox
                            edge="start"
                            checked={
                                checked.find(p => p.roleId === role.roleId && selectedGroup.usersGroupId === p.usersGroupId) ? true : false
                            }
                            tabIndex={-1}
                            disableRipple
                        />
                    </ListItemIcon>
                    <ListItemText primary={role.roleName} />
                </ListItem>
            ))}
        </List>
    );
}
